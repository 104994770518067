import _, { every } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import useAutoBasicDetailsPass from "src/pages/InputPage/Hooks/useAutoBasicDetailsPass";
import { brokerTenant } from "src/utils/tenantSettings";
import {
  api,
  useCreateCartMutation,
  useCreateEnquiryMutation,
  useDeleteCartMutation,
  useGetAdditionalDiscountsQuery,
  useGetCartQuery,
  useGetCompareFeaturesQuery,
  useGetCompareQuotesQuery,
  useGetCustomQuotesQuery,
  useGetDiscountsQuery,
  useGetFeatureOptionsQuery,
  useGetFrontendBootQuery,
  useGetRidersQuery,
  useLazyGetCartQuery,
  useLazyGetCustomQuotesQuery,
  useLazyGetQuoteQuery,
  useUpdateCartMutation,
  useUpdateCompareQuotesMutation,
  useUpdateEnquiryMutation,
  useUpdateGroupMembersMutation,
  useUpdateGroupsMutation,
} from "../api/api";

import { showErrorPopup } from "../components/CustomErrorModal/error.slice";
import useShortlistedPlans from "src/pages/ShortlistedPage/hooks/useShortlistedPlans";
import { isRenewals } from "src/utils/checkJourney";
import { refreshUserData } from "../pages/InputPage/greetingPage.slice";
import {
  setPolicyType,
  setPolicyTypes,
} from "../pages/NewQuotePage/QuotePageSlice/quote.slice";
import {
  setIsPopupOn,
  setShowErrorPopup,
} from "../pages/ProposalPage/ProposalSections/ProposalSections.slice";
import useFilters from "../pages/NewQuotePage/QuoteFilters/filters/useFilters";
import useQuoteFilter from "../pages/NewQuotePage/QuoteFilters/filters/useQuoteFilter";
import useIsPos from "../pos/useIsPos";
import { setCompareQuotesAction } from "../slices/utility.slice";
import styles from "../styles";
import {
  allowOnSpecificPages,
  calculateTotalPremium,
  capitalize,
  validateName as checkName,
  featureOptionsValidValue,
  futureDateValidator,
  getAddOnSendData,
  getMonthsForYear,
  getQuoteKey,
  getRiderCartData,
  getRiderSendData,
  getTotalPremiumWithDiscount,
  isRelianceInfinityPlan,
  matchQuotes,
  mergeQuotes,
  pastDateValidator,
} from "../utils/helper";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useGetEnquiry } from "./useGetCommonQueries";
import useUrlQuery, { useUrlQueries } from "./useUrlQuery";
import { amount, getMemberDisplayName } from "src/utils/string.utility";
import useGetRouteMatch from "./useGetRouteMatch";

const journeyTypeInsurances = {
  top_up: ["top_up"],
  health: ["health"],
  renewal: ["health"],
};

function checkInsuranceType(company, insuranceTypesToCheck = []) {
  const { insurance_types } = company;
  const isMatchInsuranceType = insurance_types.some(insurance_type =>
    insuranceTypesToCheck.includes(insurance_type),
  );
  return isMatchInsuranceType;
}

function filterCompanies(companies = {}, insurance_types = [], tenant) {
  const filteredCompanies = {};
  const renewalFilteredCompanies = {};

  for (let company_alias in companies) {
    const company = companies[company_alias];
    if (checkInsuranceType(company, insurance_types)) {
      if (companies[company_alias]?.supports_renewal) {
        renewalFilteredCompanies[company_alias] = company;
      }
      if (companies[company_alias]?.supports_new_business) {
        filteredCompanies[company_alias] = company;
      }
    }
  }

  return isRenewals(tenant) ? renewalFilteredCompanies : filteredCompanies;
}

export function useCompanies() {
  let {
    data: { companies },
    tenant,
  } = useFrontendBoot();

  const { journeyType } = useFrontendBoot();

  const insurance_types = journeyTypeInsurances[journeyType] || [];

  companies = filterCompanies(companies, insurance_types, tenant);

  function getCompany(company_alias) {
    return companies[company_alias];
  }

  function getCompanyLogo(company_alias) {
    const company = getCompany(company_alias);

    if (!company) return;

    return company.logo;
  }

  function getCompanies(insurance_type) {
    return Object.values(companies)
      ?.filter(company => company.insurance_types.includes(insurance_type))
      ?.map(company => company.alias);
  }

  return {
    getCompany,
    getCompanyLogo,
    getCompanies,
    companies,
  };
}

export function useQuote(skip = false) {
  const [createCart, queryState] = useCreateCartMutation();
  const { journeyType } = useFrontendBoot();
  const { currentGroupObject } = useGetEnquiry();
  const { data } = useGetCartQuery(undefined, skip);

  const { isPortGroup } = usePortJourneyConfig();

  function buyQuote(quote, riders = []) {
    const valuedCartArray = data?.data?.filter(cart => !Array.isArray(cart));
    valuedCartArray?.forEach(cart => {
      if (cart?.group?.type !== currentGroupObject.type) {
        deleteQuote(cart.id);
      }
    });

    let { feature_options = null } = quote;
    if (Object.keys(feature_options ?? {}).length === 0) {
      feature_options = null;
    } else {
      feature_options = quote?.feature_options;
    }

    const quoteData = {
      ...quote,
      feature_options: feature_options,
      product_id: quote.product.id,
      group_id: +currentGroupObject.id,
      service_tax: quote.tax_amount,
      supports_port: isPortGroup(currentGroupObject?.id) ? 1 : 0,
      [journeyType === "health" ? "riders" : "top_up_riders"]:
        riders?.map(getRiderCartData),
    };
    return createCart(quoteData);
  }

  const [deleteCart] = useDeleteCartMutation();

  function deleteQuote(cartId) {
    return deleteCart(cartId);
  }

  return { buyQuote, deleteQuote, queryState };
}

export function useTheme() {
  const {
    data: {
      settings: {
        primary_color,
        primary_shade,
        secondary_color,
        secondary_shade,
      },
    },
  } = useGetFrontendBootQuery();

  const { PrimaryColor, SecondaryColor, PrimaryShade, SecondaryShade } =
    useSelector(state => state.frontendBoot.theme);
  return {
    ...styles,
    colors: {
      ...styles.colors,
      primary_color: PrimaryColor || primary_color,
      primary_shade: PrimaryShade || primary_shade,
      secondary_color: SecondaryColor || secondary_color,
      secondary_shade: SecondaryShade || secondary_shade,
    },
  };
}

export function useSortBy() {
  const sortByOptionsConvertor = string => {
    if (!string) return null;
    const sortByOptions = string;
    return sortByOptions?.map(opt => ({
      code: opt,
      display_name: opt.split("_").join(" "),
    }));
  };

  const SORT_BY_OPTIONS = [
    {
      code: "relevance",
      display_name: "Relevance",
    },
    {
      code: "premium_low_to_high",
      display_name: "Premium Low To High",
    },
    {
      code: "premium_high_to_low",
      display_name: "Premium High To Low",
    },
  ];

  const {
    data: { sortbys },
  } = useFrontendBoot();

  const sortByOptions = sortByOptionsConvertor(sortbys) || SORT_BY_OPTIONS;
  return {
    SORT_BY_OPTIONS: sortByOptions,
    default: sortByOptions[0],
  };
}

export function useFilterOrder() {
  const {
    data: {
      settings: { broker_order_by },
    },
  } = useFrontendBoot();

  const filterOrder = broker_order_by
    ? JSON.parse(broker_order_by)
    : [
        "sort_by",
        "premium",
        "cover",
        "policy_type",
        "multiyear_option",
        "plan_type",
        "insurer",
        "more_filter",
      ];

  return {
    filterOrder,
  };
}

export function useFilter() {
  const {
    data: {
      defaultfilters: { cover, tenure, plan_type },
    },
    journeyType,
    subJourneyType,
  } = useFrontendBoot();

  const {
    data: {
      data: { groups },
    },
  } = useGetEnquiry();

  function getFilters(groupCode) {
    let currentGroup = groups.find(group => group?.id === parseInt(groupCode));

    const { extras } = currentGroup;

    let tenureFilter = tenure;
    let coverFilter = cover;
    let base_plan_type =
      journeyType === "top_up"
        ? "topup_plan"
        : subJourneyType === "port"
        ? "port_plan"
        : "base_health"; // logic for default plan type filter
    let planTypeFilter = plan_type;

    if (extras) {
      if (extras.tenure) tenureFilter = extras.tenure;
      if (extras.cover) coverFilter = extras.cover;
      if (extras.baseplantype) base_plan_type = extras.baseplantype;
      if (extras.plantype) planTypeFilter = extras.plantype;
    }
    return {
      tenure: tenureFilter,
      cover: coverFilter,
      base_plan_type,
      plantype: planTypeFilter,
    };
  }

  return { getFilters };
}

export function useMembers() {
  const dispatch = useDispatch();
  let {
    data: { members },
  } = useFrontendBoot();

  const { data, groups, input } = useGetEnquiry();
  const { selectedGroup } = useSelector(state => state.quotePage);
  const genderOfSelf = data?.data?.input?.gender;

  useEffect(() => {
    dispatch(refreshUserData(data?.data));
  }, []);

  useEffect(() => {
    const groupPolicyTypes = {};
    if (data) {
      const group = data?.data?.groups?.find(el => {
        //* only "==" for avoiding type casting, do not modify to "==="
        return el.id == selectedGroup;
      });
      if (group) {
        dispatch(setPolicyType(group?.plan_type));
      }
      data?.data?.groups.forEach(group => {
        groupPolicyTypes[group?.id] =
          group?.plan_type && group?.plan_type?.startsWith("F")
            ? "Family Floater"
            : group?.plan_type?.startsWith("M")
            ? "Multi Individual"
            : "Individual";
      });
      dispatch(setPolicyTypes({ ...groupPolicyTypes }));
    }
    return () => {};
  }, [data, selectedGroup]);

  const selectedMembers = (input?.members || [])?.map(member => ({
    ...member,
    code: member.type,
  }));

  const isMemberSelected = code => {
    return selectedMembers.some(member => member.code === code);
  };

  const getMember = code => {
    let member = members.find(
      member => member.code === code.replace(/[0-9]/g, ""),
    );

    if (!member) return;

    const selectedDetails = selectedMembers.find(
      member => member.code === code,
    );

    member = { ...member, multiple: ["son", "daughter"].includes(code) };

    if (selectedDetails) {
      const { age } = selectedDetails;
      return {
        ...member,
        code,
        display_name: member.display_name,
        isSelected: true,
        age: {
          code: age,
          display_name: age
            ? age < 1
              ? getMonthsForYear(age) + " Months"
              : age + " Years"
            : "",
          short_display_name: age
            ? age < 1
              ? getMonthsForYear(age) + " M"
              : age + " Y"
            : "",
        },
      };
    }

    return member;
  };

  const getSelectedMembers = () =>
    selectedMembers?.map(selectedMember => getMember(selectedMember?.code));

  const getAllMembers = () => {
    const allMembers = [];

    const isSingleSon =
      input?.members?.filter(member => member?.code?.startsWith("son"))
        .length == 1 || false;

    const isSingleDaughter =
      input?.members?.filter(member => member?.code?.startsWith("daughter"))
        .length == 1 || false;
    members
      ?.map(member => getMember(member.code))
      ?.forEach(member => {
        if (!member.multiple) {
          allMembers.push(member);
          return;
        }

        let multipleMembers = selectedMembers?.filter(selectedMember =>
          selectedMember.code?.startsWith(member.code),
        );

        if (!multipleMembers?.length) {
          allMembers.push({
            ...member,
            code: `${member.code}${1}`,
            display_name: `${member.display_name}`,
            base: { code: member.code, display_name: member.display_name },
          });
          return;
        }

        multipleMembers = multipleMembers?.map((multipleMember, idx) => {
          return {
            ...member,
            code: multipleMember.code,
            age: {
              code: multipleMember.age,
              display_name: multipleMember?.age
                ? multipleMember?.age < 1
                  ? getMonthsForYear(multipleMember?.age) + " Months"
                  : multipleMember?.age + " Years"
                : "Below 3 Months",
              short_display_name: multipleMember?.age
                ? multipleMember?.age < 1
                  ? getMonthsForYear(multipleMember?.age) + " M"
                  : multipleMember?.age + " Y"
                : "Below 3 M",
            },
            display_name: getMemberDisplayName({
              memberCode: multipleMember.code,
              isSingleSon,
              isSingleDaughter,
            }),
            isSelected: true,
            multiple: idx === 0,
            base: { code: member.code, display_name: member.display_name },
          };
        });

        allMembers.splice(allMembers.length, 0, ...multipleMembers);
      });
    return allMembers;
  };

  const getGroupMembers = groupCode => {
    const group = getGroup(groupCode);

    if (!group) return;

    const { members: groupMembers } = group;

    const allMembers = getAllMembers();

    const returnMembers = allMembers.filter(member =>
      groupMembers.includes(member.code),
    );

    const updatedMembers = returnMembers.map(member => {
      return {
        ...member,
        display_code: member.display_name,
      };
    });
    return updatedMembers;
  };

  function getGroup(groupCode) {
    return groups?.find(group => group?.id === parseInt(groupCode));
  }

  function getGroupLocation(groupCode) {
    const group = getGroup(groupCode);
    if (!group) return;

    return {
      city: group?.city,
      state: group?.state,
      pincode: group?.pincode,
    };
  }

  function getGroupPortExpiryDate(groupCode) {
    const group = getGroup(groupCode);
    if (!group) return;

    return group?.port_expiry_date ? new Date(group?.port_expiry_date) : null;
  }

  function getFirstGroupLocation() {
    return (
      groups?.length && {
        city: groups[0]?.city,
        state: groups[0]?.state,
        pincode: groups[0]?.pincode,
      }
    );
  }

  function getNextGroup(currentGroupCode) {
    return groups
      ?.filter(group => group.type !== "all")
      ?.find(group => group?.id === currentGroupCode + 1);
  }

  function getPreviousGroup(currentGroupCode) {
    return groups?.find(group => group?.id === currentGroupCode - 1);
  }

  function getLastGroup() {
    if (!groups) return;
    return groups[groups.length - 1];
  }

  function getMembersText({ id }) {
    const groupMembers = getGroupMembers(id);
    return groupMembers
      ?.map(member => {
        if (
          !member?.multiple &&
          (member?.code.startsWith("son") ||
            member?.code.startsWith("daughter"))
        ) {
          return member?.code;
        }
        return member?.display_name;
      })
      ?.join(", ");
  }

  function checkGroupExist(groupCode) {
    return groups?.some(group => group?.id === parseInt(groupCode));
  }

  return {
    getGroupMembers,
    isMemberSelected,
    getMember,
    getSelectedMembers,
    getAllMembers,
    getGroup,
    getGroupLocation,
    getFirstGroupLocation,
    getNextGroup,
    getPreviousGroup,
    genderOfSelf,
    getLastGroup,
    getMembersText,
    checkGroupExist,
    groups,
    getGroupPortExpiryDate,
  };
}

export function useUpdateGroupMembers(groupCode) {
  const dispatch = useDispatch();
  const [updateGroupMembersMutation, query] = useUpdateGroupMembersMutation();
  const { getFilters } = useFilter();
  const { getCartEntry } = useCart();

  const updateGroupMembers = updatedMembers => {
    const { tenure, cover, base_plan_type, plantype } = getFilters(groupCode);

    const { product, sum_insured } = getCartEntry(groupCode);

    return updateGroupMembersMutation({
      members: updatedMembers,
      filters: {
        sum_insured_range: cover?.code || cover,
        tenure: tenure,
        base_plan_type: base_plan_type,
        plan_type: plantype,
        group: groupCode,
      },
      quote: { product, sum_insured },
    }).then(res => {
      if (res?.error) return res;
      const { updateEnquiriesResult } = res?.data;

      dispatch(
        api.util.updateQueryData("getEnquiries", undefined, enquiriesDraft => {
          Object.assign(enquiriesDraft, updateEnquiriesResult);
        }),
      );
      return res;
    });
  };

  return {
    updateGroupMembers,
    query,
  };
}

export function useUpdateEnquiry() {
  const [updateEnquiryMutation, queryState] = useUpdateEnquiryMutation();

  const [updateGroups, updateGroupsQueryState] = useUpdateGroupsMutation();

  const dispatch = useDispatch();

  const { data } = useGetEnquiry();

  async function updateEnquiry(data) {
    //TODO : this condition to be tested
    if (
      data?.pincode &&
      (data?.port_expiry_date || data?.port_expiry_date + "" === null + "")
    ) {
      const { groupCode, ...sendData } = data;

      const updateGroupsResponse = await updateGroups({
        groupCode,
        pincode: data?.pincode,
        port_expiry_date: data?.port_expiry_date,
      });

      const updateEnquiryResponse = await updateEnquiryMutation(sendData);

      return [updateGroupsResponse, updateEnquiryResponse];
    }

    if (data?.pincode) {
      const { groupCode, ...sendData } = data;

      const updateGroupsResponse = await updateGroups({
        groupCode,
        pincode: data?.pincode,
      });

      const updateEnquiryResponse = await updateEnquiryMutation(sendData);

      return [updateGroupsResponse, updateEnquiryResponse];
    }

    if (data?.port_expiry_date || data?.port_expiry_date + "" === null + "") {
      const { groupCode, ...sendData } = data;

      const updateGroupsResponse = await updateGroups({
        groupCode,
        port_expiry_date: data?.port_expiry_date,
      });

      const updateEnquiryResponse = await updateEnquiryMutation(sendData);

      return [updateGroupsResponse, updateEnquiryResponse];
    }
    return updateEnquiryMutation(data);
  }

  const updateEnquiryData = updatedEnquiryData => {
    dispatch(
      api.util.updateQueryData("getEnquiries", undefined, enquiriesDraft => {
        return Object.assign(enquiriesDraft, {
          ...enquiriesDraft,
          data: updatedEnquiryData,
        });
      }),
    );
  };

  return {
    ...queryState,
    error: queryState.error,
    updateEnquiry,
    isLoading: queryState.isLoading || updateGroupsQueryState.isLoading,
    updateEnquiryData,
    enquiryData: data?.data,
  };
}

export function useUpdateMembers() {
  const { journeyType, subJourneyType } = useFrontendBoot();

  const {
    data: { data: enquiryData },
  } = useGetEnquiry();
  const [createEnquiry, queryState] = useCreateEnquiry();

  const { replaceShortlistedPlans } = useShortlistedPlans();

  const history = useHistory();

  const dispatch = useDispatch();

  const { getSelectedFilter } = useFilters();

  function updateMembers({
    members,
    redirectToQuotes,
    sendData,
    ...data
  } = {}) {
    const updateData = {
      email: enquiryData.email,
      enquiryId: enquiryData.enquiry_id,
      mobile: enquiryData.mobile,
      name: enquiryData.name,
      gender: enquiryData.input.gender,
      deductible: enquiryData.input.deductible,
      params: enquiryData.input.params,
      existing_diseases: enquiryData.input.existing_diseases,
      type: !redirectToQuotes
        ? "renew"
        : subJourneyType === "port"
        ? "port"
        : undefined,
      action: !redirectToQuotes ? "update_members" : undefined,
      members: sendData.members,
      plan_type:
        journeyType === "health"
          ? members?.length === 1
            ? "I"
            : getSelectedFilter("plantype")?.code === "I"
            ? JSON.parse(localStorage.getItem("default_filters"))?.plan_type ||
              "F"
            : getSelectedFilter("plantype")?.code
          : journeyType === "top_up"
          ? members?.length === 1
            ? "I"
            : "F"
          : "I",
      pincode: enquiryData?.input?.pincode,
      ...data,
      groups: enquiryData.groups,
    };

    const currentGroup =
      localStorage.getItem("groups") &&
      JSON.parse(localStorage.getItem("groups")).find(group => group?.id);

    return createEnquiry({ ...updateData, updateCache: false }).then(
      response => {
        if (!response.data) return response;
        const {
          data: {
            data: { groups, enquiry_id },
          },
        } = response;
        replaceShortlistedPlans([]);
        if (redirectToQuotes) {
          history.push({
            pathname: `/quotes/${groups[0]?.id}`,
            search: `enquiryId=${enquiry_id}&pincode=${currentGroup?.pincode}&city=${currentGroup?.city}`,
          });
        } else {
          history.push({
            pathname: `/proposal`,
            search: `enquiryId=${enquiry_id}`,
          });
        }
        dispatch(
          api.util.updateQueryData("getEnquiries", undefined, draft => {
            Object.assign(draft, response.data);
          }),
        );
        dispatch(api.util.invalidateTags(["Cart"]));
        return response;
      },
    );
  }

  return { updateMembers, ...queryState };
}

function filterInvalidRiders(rider) {
  // remove riders with the below conditions as they are invalid and need not be shown in the ui.
  if (rider.total_premium <= 0) {
    if (rider.is_rider_default == undefined || rider.is_mandatory == undefined)
      return;
  }
  return rider;
}

export function filterInvalidRidersExceptFreeRider(rider) {
  const {
    is_rider_default = false,
    is_rider_free = false,
    calculate_premium_on_selection = false,
  } = rider;

  // remove invalid riders except for the conditions in switch statements
  if (rider.total_premium <= 0) {
    switch (true) {
      case is_rider_default || is_rider_free: {
        return rider;
      }
      case calculate_premium_on_selection: {
        return rider;
      }
      default: {
        return;
      }
    }
  }
  return rider;
}

export function riderPremiumValue({ rider, includeTax = false }) {
  const {
    total_premium,
    tax_amount,
    is_rider_default = false,
    is_rider_free = false,
  } = rider || {};

  switch (true) {
    case is_rider_default || is_rider_free: {
      return "Included";
    }
    default: {
      return includeTax
        ? amount(total_premium)
        : amount(total_premium - tax_amount);
    }
  }
}

export function useCart() {
  const dispatch = useDispatch();
  const searchQueries = useUrlQueries();
  const { isInputMembersRoute, isInputPagesRoute, isBasicDetailsRoute } =
    useGetRouteMatch();

  const isInputPage =
    isInputMembersRoute || isInputPagesRoute || isBasicDetailsRoute;

  const { data, isLoading } = useGetCartQuery(undefined, {
    skip: !searchQueries.enquiryId || isInputPage,
  });

  const { data: enquiryData } = useGetEnquiry();

  const groups = enquiryData?.data?.groups || [];

  const { getCompany } = useCompanies();

  const { journeyType, subJourneyType } = useFrontendBoot();

  const { checkedRiders } = useSelector(({ productPage }) => productPage);

  let selectedRiders = [];

  let updatedCartEntry = data?.data?.filter(entry => !Array.isArray(entry));

  updatedCartEntry = updatedCartEntry?.map(entry => ({
    ...entry,
    netPremiumWithoutDiscount: calculateTotalPremium(
      entry,
      {},
      journeyType,
      subJourneyType,
      selectedRiders,
    ),
  }));

  // Cart items call for kyc in case of renewals resets the selected riders array
  if (subJourneyType === "renewal") {
    selectedRiders = checkedRiders?.[groups[0].id]?.filter(
      rider => rider?.isSelected,
    );
  }

  const getCartTotalPremium = () => {
    return data?.discounted_total_premium;
  };

  function getCartEntry(groupCode, { additionalDiscounts = [] } = {}) {
    let cartEntry =
      updatedCartEntry?.find(
        cartEntry => +cartEntry?.group?.id === parseInt(groupCode),
      ) || {};

    const health_riders =
      cartEntry?.health_riders
        ?.map(filterInvalidRidersExceptFreeRider)
        .filter(Boolean) || [];
    const top_up_riders =
      cartEntry?.top_up_riders?.map(filterInvalidRiders).filter(Boolean) || [];

    if (!cartEntry) {
      return {};
    }

    const group = groups?.find(
      group => parseInt(group?.id) === parseInt(groupCode),
    );

    const { logo: icLogoSrc } =
      getCompany(cartEntry?.product?.company?.alias) || {};

    return {
      ...cartEntry,
      health_riders,
      top_up_riders,
      plantype: group?.plan_type,
      netPremium: calculateTotalPremium(
        cartEntry,
        { additionalDiscounts },
        journeyType,
      ),
      netPremiumWithoutDiscount: calculateTotalPremium(
        cartEntry,
        {},
        journeyType,
        subJourneyType,
        selectedRiders,
      ),
      icLogoSrc,
    };
  }

  function updateCartEntry(groupCode, updatedCartEntry) {
    dispatch(
      api.util.updateQueryData("getCart", undefined, cartDraft => {
        if (cartDraft) {
          Object.assign(cartDraft, {
            ...cartDraft,
            data: cartDraft?.data?.map(cartEntry => {
              return cartEntry?.group?.id === +groupCode
                ? {
                    ...cartEntry,
                    ...updatedCartEntry,
                  }
                : cartEntry;
            }),
          });
        }
      }),
    );
  }

  const [updateCartMutation, updateCartMutationQuery] = useUpdateCartMutation();

  function updateCart(groupCode) {
    if (groupCode && getCartEntry(groupCode)) {
      const { id, health_riders, top_up_riders, ...cartEntry } =
        getCartEntry(groupCode);

      return [
        ({
          discounted_total_premium,
          generate_proposal = false,
          with_modify = false,
          feature_options = {},
          additionalArgs = {},
          selectedRiders = [],
          sum_insured = cartEntry?.sum_insured,
        }) => {
          return updateCartMutation({
            ...cartEntry,
            sum_insured,
            cartId: id,
            [journeyType === "health" ? "riders" : "top_up_riders"]:
              journeyType === "health"
                ? subJourneyType === "renewal" && !health_riders?.length
                  ? selectedRiders
                  : health_riders?.map(getRiderSendData)
                : top_up_riders?.map(getRiderSendData),
            addons: cartEntry.addons?.map(getAddOnSendData),
            discounted_total_premium,
            feature_options,
            generate_proposal,
            with_modify,
            discounts: cartEntry?.discounts?.filter(
              singleDiscount => singleDiscount,
            ),
            ...additionalArgs,
          });
        },
        updateCartMutationQuery,
      ];
    }
    return [() => {}, {}];
  }

  function getNextGroupProduct(currentGroupCode) {
    const nextGroup = currentGroupCode + 1;
    const nextGroupProduct = updatedCartEntry?.find(
      cartEntry => parseInt(cartEntry?.group?.id) === nextGroup,
    );

    return nextGroupProduct;
  }

  function getPreviousGroupProduct(currentGroupCode) {
    // when groups are combined then on going back we need to go to quotes page that's why returning false
    const isCombinedGroup =
      updatedCartEntry.find(
        entry => parseInt(entry?.group?.id) === parseInt(currentGroupCode),
      )?.group.type === "all";

    if (isCombinedGroup) return false;
    const prevGroup = currentGroupCode - 1;
    const prevGroupProduct = updatedCartEntry?.find(
      cartEntry => parseInt(cartEntry?.group?.id) === prevGroup,
    );

    return prevGroupProduct;
  }

  const checkKYCVerified = () => {
    const cartEntries = updatedCartEntry;
    let result = false;
    const keyExist = cartEntries.every(cartEntry =>
      cartEntry?.hasOwnProperty("can_proceed_with_proposal"),
    );
    if (keyExist) {
      result = cartEntries.every(
        cartEntry => cartEntry?.can_proceed_with_proposal,
      )
        ? true
        : false;
    } else {
      result = "KEY_NOT_FOUND";
    }
    //* result === true : KYC is verified successfully by the user.
    //* result === false : User failed the KYC verification.
    //* result === "KEY_NOT_FOUND" : User haven't initiated KYC verification.
    return result;
  };

  const isVersionRuleEngine = groupId => {
    const cartEntries = updatedCartEntry;
    let result =
      cartEntries?.find(entry => entry.group.id == groupId)?.product
        ?.version === "rule_engine";
    return result;
  };

  let cartDataExist = !!updatedCartEntry?.length;
  return {
    cartEntries: updatedCartEntry,
    cartData: data || {},
    getCartEntry,
    updateCartEntry,
    updateCart,
    getCartTotalPremium,
    getNextGroupProduct,
    discounted_total_premium: data?.discounted_total_premium,
    isVersionRuleEngine,
    getPreviousGroupProduct,
    isLoading,
    cartDataExist,
    checkKYCVerified,
  };
}

export function useRider(groupCode) {
  const { getCartEntry, updateCartEntry } = useCart();

  const { journeyType } = useFrontendBoot();

  function getSelectedRiders() {
    const cartEntry = getCartEntry(groupCode);

    const health_riders = cartEntry?.health_riders || [];
    const top_up_riders = cartEntry?.top_up_riders || [];

    if (isRelianceInfinityPlan(cartEntry))
      return health_riders?.length ? health_riders : top_up_riders;

    return journeyType === "health" ? health_riders : top_up_riders;
  }

  function replaceRiders(riders = []) {
    const ridersCartData = riders?.map(getRiderCartData);

    let ridersDataObj = {};
    if (journeyType === "health")
      ridersDataObj = { health_riders: ridersCartData };
    if (journeyType === "top_up")
      ridersDataObj = { top_up_riders: ridersCartData };

    updateCartEntry(groupCode, ridersDataObj);
  }

  return { getSelectedRiders, replaceRiders };
}

export function useTenureDiscount(groupCode, skipToken = false) {
  const { journeyType, subJourneyType } = useFrontendBoot();

  const { updateCartEntry, getCartEntry } = useCart();

  const dispatch = useDispatch();

  const cartEntry = getCartEntry(groupCode);

  const {
    product,
    sum_insured,
    tenure,
    deductible,
    discounts = [],
    additional_discounts = [],
  } = cartEntry;

  const { getSelectedFeatureOptions } = useFeatureOptions();

  const { isPortPlan } = usePortJourneyConfig();

  const updatedFeatureOptions = getSelectedFeatureOptions(groupCode);

  const featureOptionsToSend = Object.keys(updatedFeatureOptions)
    ?.map(key => `${key}=${updatedFeatureOptions[key]}`)
    ?.join("&");

  const { data, ...queryState } = useGetDiscountsQuery(
    {
      sum_insured: +sum_insured,
      product_id: product?.id,
      group: groupCode,
      feature_options: featureOptionsToSend,
      journeyType,
      subJourneyType,
      isPortPlan: isPortPlan(groupCode),
      deductible,
      discountAlias:
        discounts && additional_discounts?.[0]?.get_updated_discount
          ? discounts.join("&")
          : "",
      additional_discount_policy_number:
        additional_discounts?.[0]?.additional_discount_policy_number,
    },
    { skip: skipToken },
  );

  function applyTenureDiscount({
    tax_amount,
    sum_insured,
    tenure,
    total_premium,
    premium,
  }) {
    updateCartEntry(groupCode, {
      service_tax: tax_amount,
      sum_insured,
      tenure,
      total_premium,
      premium,
    });
    dispatch(api.util.invalidateTags(["featureOption"]));
  }

  function isTenureDiscountSelected(tenureDiscount) {
    return parseInt(tenureDiscount.tenure) === parseInt(tenure);
  }

  return {
    applyTenureDiscount,
    isTenureDiscountSelected,
    query: { ...queryState, data },
  };
}

export function useMultiYearDiscount(discounts = [], groupCode) {
  const { getCartEntry } = useCart();

  const cartEntry = getCartEntry(groupCode);

  const { product, sum_insured } = cartEntry;

  const { getSelectedFeatureOptions } = useFeatureOptions();

  const updatedFeatureOptions = getSelectedFeatureOptions(groupCode);

  const featureOptionsToSend = Object.keys(updatedFeatureOptions)
    ?.map(key => `${key}=${updatedFeatureOptions[key]}`)
    ?.join("&");

  const [skipToken, setSkipToken] = useState(true);

  const {
    data = {},
    isLoading,
    isFetching,
  } = useGetDiscountsQuery(
    {
      sum_insured: +sum_insured,
      product_id: product?.id,
      group: groupCode,
      ageWiseBasePremium: true,
      feature_options: featureOptionsToSend,
    },
    { skip: skipToken },
  );

  const yearOnePremium =
    discounts?.find(discount => discount?.tenure === 1)?.total_premium || 0;
  const yearOnePremiumWithoutTax =
    discounts?.find(discount => discount?.tenure === 1)?.premium || 0;

  const [yearTwoPremium, setYearTwoPremium] = useState(0);
  const [yearThreePremium, setYearThreePremium] = useState(0);

  const [secondYearBasePremium, setSecondYearBasePremium] = useState(0);
  const [thirdYearBasePremium, setThirdYearBasePremium] = useState(0);

  const [secondYearBasePremiumWithoutTax, setSecondYearBasePremiumWithoutTax] =
    useState(0);
  const [thirdYearBasePremiumWithoutTax, setThirdYearBasePremiumWithoutTax] =
    useState(0);

  useEffect(() => {
    if (discounts.length) {
      setSkipToken(false);
    }
  }, [JSON.stringify(discounts)]);

  useEffect(() => {
    if (data?.data) {
      if (product?.tenure_discount_rule === "getCombinedTaxPremium") {
        setSecondYearBasePremiumWithoutTax(
          data?.data?.find(discount => discount?.[`age-bracket1`])?.premium ||
            0,
        );
        setThirdYearBasePremiumWithoutTax(
          data?.data?.find(discount => discount?.[`age-bracket2`])?.premium ||
            0,
        );
        return;
      }

      setSecondYearBasePremium(
        data?.data?.find(discount => discount?.[`age-bracket1`])
          ?.total_premium || 0,
      );
      setThirdYearBasePremium(
        data?.data?.find(discount => discount?.[`age-bracket2`])
          ?.total_premium || 0,
      );
      setSkipToken(true);
    }
  }, [data?.data]);

  useEffect(() => {
    if (secondYearBasePremium || secondYearBasePremiumWithoutTax) {
      const yearTwoDiscount =
        +discounts?.find(discount => discount.tenure === 2)?.yearly_discount ||
        0;

      const getSecondYearPremium = () => {
        const premiumToCheck = secondYearBasePremium + yearOnePremium;

        if (product?.tenure_discount_rule === "getIndividualPremium") {
          const secondYearDiscounted =
            secondYearBasePremium * ((100 - yearTwoDiscount) / 100);
          return secondYearDiscounted + yearOnePremium;
        }

        if (product?.tenure_discount_rule === "getActualPremium") {
          return yearOnePremium * 2 * ((100 - yearTwoDiscount) / 100);
        }

        if (product?.tenure_discount_rule === "getDiscountedPremium") {
          return yearOnePremium * ((100 - yearTwoDiscount) / 100) * 2;
        }

        if (product?.tenure_discount_rule === "getCombinedTaxPremium") {
          return (
            (yearOnePremiumWithoutTax + secondYearBasePremiumWithoutTax) *
            ((100 - yearTwoDiscount) / 100)
          );
        }

        if (product?.tenure_discount_rule === "getCombinedPremium") {
          return premiumToCheck * ((100 - yearTwoDiscount) / 100);
        }

        return 0;
      };

      setYearTwoPremium(getSecondYearPremium());
    }

    if (thirdYearBasePremium || thirdYearBasePremiumWithoutTax) {
      const yearThreeDiscount =
        +discounts?.find(discount => discount.tenure === 3)?.yearly_discount ||
        0;

      const getThirdYearPremium = () => {
        const premiumToCheck =
          thirdYearBasePremium + secondYearBasePremium + yearOnePremium;

        if (product?.tenure_discount_rule === "getIndividualPremium") {
          const yearTwoFetchedPremium = discounts?.find(
            discount => discount.tenure === 2,
          )?.total_premium;
          const thirdYearDiscounted =
            thirdYearBasePremium * ((100 - yearThreeDiscount) / 100);
          // yearTwoFetchedPremium already contains yearOnePremium
          return thirdYearDiscounted + yearTwoFetchedPremium;
        }

        if (product?.tenure_discount_rule === "getActualPremium") {
          return yearOnePremium * 3 * ((100 - yearThreeDiscount) / 100);
        }

        if (product?.tenure_discount_rule === "getDiscountedPremium") {
          return yearOnePremium * ((100 - yearThreeDiscount) / 100) * 3;
        }

        if (product?.tenure_discount_rule === "getCombinedTaxPremium") {
          return (
            (yearOnePremiumWithoutTax +
              secondYearBasePremiumWithoutTax +
              thirdYearBasePremiumWithoutTax) *
            ((100 - yearThreeDiscount) / 100)
          );
        }

        if (product?.tenure_discount_rule === "getCombinedPremium") {
          return premiumToCheck * ((100 - yearThreeDiscount) / 100);
        }

        return 0;
      };

      setYearThreePremium(getThirdYearPremium());
    }
  }, [
    secondYearBasePremium,
    thirdYearBasePremium,
    thirdYearBasePremiumWithoutTax,
    secondYearBasePremiumWithoutTax,
  ]);

  const getDiscountAmount = (fetchedPremium, tenure, rule = "") => {
    const actualPremium =
      tenure === 2 ? yearTwoPremium : tenure === 3 ? yearThreePremium : 0;

    if (
      fetchedPremium - Math.round(actualPremium) > 5 ||
      fetchedPremium - Math.round(actualPremium) < -5
    ) {
      return 0;
    }

    if (rule === "getCombinedTaxPremium") {
      return tenure === 2
        ? secondYearBasePremiumWithoutTax +
            yearOnePremiumWithoutTax -
            fetchedPremium
        : tenure === 3
        ? thirdYearBasePremiumWithoutTax +
          secondYearBasePremiumWithoutTax +
          yearOnePremiumWithoutTax -
          fetchedPremium
        : 0;
    }

    return tenure === 2
      ? secondYearBasePremium + yearOnePremium - fetchedPremium
      : tenure === 3
      ? thirdYearBasePremium +
        secondYearBasePremium +
        yearOnePremium -
        fetchedPremium
      : 0;
  };

  return {
    getDiscountAmount,
    yearOnePremium,
    isDiscountLoading: isLoading || isFetching,
  };
}

const discountOnAnotherDiscount = ({
  discounts_on_which_discount_to_be_applied,
  total_premium,
  cartEntry,
}) => {
  return discounts_on_which_discount_to_be_applied
    ?.map(
      ({
        applied_on_total_premium: applied_on_total_cart_premium,
        applied_on_riders,
        percent: percent_of_discount_on_which_discount_to_be_applied,
        fixed_discount_value,
        applied_on_base_premium,
      }) => {
        let discountValue = 0;

        if (fixed_discount_value) {
          //? return the amount directly if it is fixed.
          return +fixed_discount_value;
        }

        if (applied_on_base_premium) {
          return (
            (+cartEntry?.premium *
              +percent_of_discount_on_which_discount_to_be_applied) /
            100
          );
        }

        if (applied_on_total_cart_premium) {
          //? Means applied on cart total premium.
          const discount =
            (+cartEntry?.netPremiumWithoutDiscount *
              +percent_of_discount_on_which_discount_to_be_applied) /
            100;

          return (discountValue = discountValue + discount);
        }

        if (!applied_on_total_cart_premium) {
          const discount =
            (+total_premium *
              +percent_of_discount_on_which_discount_to_be_applied) /
            100;
          discountValue = discountValue + discount;
        }

        if (applied_on_riders) {
          const filtered_applied_on_riders = cartEntry.health_riders.filter(
            singleHealthRider =>
              applied_on_riders.includes(singleHealthRider?.alias),
          );

          const filtered_applied_on_riders_amount =
            filtered_applied_on_riders.length
              ? filtered_applied_on_riders
                  ?.map(rider => rider.total_premium)
                  ?.reduce((acc = 0, curr) => (acc += +curr))
              : 0;

          const discount =
            (+filtered_applied_on_riders_amount *
              +percent_of_discount_on_which_discount_to_be_applied) /
            100;

          discountValue = discountValue + discount;
        }

        return discountValue;
      },
    )
    ?.reduce((acc = 0, curr) => (acc += +curr));
};

export function useAdditionalDiscounts() {
  const { getCartEntry } = useCart();

  const getSelectedAdditionalDiscountsAlias = groupCode => {
    const { discounts = [] } = getCartEntry(groupCode) || {};
    return discounts;
  };

  return { getSelectedAdditionalDiscountsAlias };
}

export function useAdditionalDiscount(groupCode, skip = false) {
  const { getCartEntry, updateCartEntry } = useCart();

  const skipToken = skip || !groupCode;

  const {
    product,
    sum_insured,
    tenure,
    discounts = [],
    total_premium,
    premium,
  } = getCartEntry(groupCode) || {};

  const { subJourneyType } = useFrontendBoot();

  const cartEntry = getCartEntry(groupCode);

  const {
    data,
    refetch: refetchAdditionalDiscount,
    ...queryState
  } = useGetAdditionalDiscountsQuery(
    {
      productId: product?.id,
      groupCode,
      sum_insured,
      tenure,
      subJourneyType,
    },
    { skip: skipToken },
  );

  function addAdditionalDiscount(additionalDiscount) {
    refetchAdditionalDiscount();
    if (additionalDiscount.selected_option) {
      let temp_discounts = [...discounts];

      const updateSelectedDiscountAlias = temp_discounts.find(discount =>
        discount?.startsWith(additionalDiscount?.alias),
      );

      //? if selected another percent option (when discount is already applied) than it will update the discount only.
      if (updateSelectedDiscountAlias) {
        temp_discounts = temp_discounts.filter(
          discount => !discount.startsWith(additionalDiscount?.alias),
        );
      }

      return updateCartEntry(groupCode, {
        discounts: [
          ...temp_discounts,
          `${additionalDiscount?.alias}:${additionalDiscount?.selected_option}`,
        ],
      });
    }

    return updateCartEntry(groupCode, {
      discounts: [...discounts, additionalDiscount?.alias],
      additional_discounts: [
        ...cartEntry.additional_discounts,
        additionalDiscount,
      ],
    });
  }

  function removeAdditionalDiscount(additionalDiscount) {
    const updatedAdditionalDiscounts = cartEntry.additional_discounts?.filter(
      discount => discount.id !== additionalDiscount.id,
    );

    updateCartEntry(groupCode, {
      discounts: discounts?.filter(
        selectedAdditionalDiscount =>
          !selectedAdditionalDiscount.startsWith(additionalDiscount?.alias),
      ),
      additional_discounts: updatedAdditionalDiscounts,
    });
  }

  function isAdditionalDiscountSelected(additionalDiscount) {
    if (additionalDiscount?.selected_option) {
      return discounts?.includes(
        `${additionalDiscount?.alias}:${additionalDiscount?.selected_option}`,
      );
    }
    return !!discounts?.find(discount_alias =>
      discount_alias?.startsWith(additionalDiscount?.alias),
    );
  }

  function toggleAdditionalDiscount(additionalDiscount) {
    const isSelected = isAdditionalDiscountSelected(additionalDiscount);
    if (isSelected) {
      return removeAdditionalDiscount(additionalDiscount);
    }
    addAdditionalDiscount(additionalDiscount);
  }

  const getTotalDiscountAmount = () => {
    const selectedAdditionalDiscount = getSelectedAdditionalDiscounts();

    const totalDiscountAmount = selectedAdditionalDiscount?.length
      ? selectedAdditionalDiscount
          ?.map(discount => {
            const { is_discount_default = false } = discount;
            return is_discount_default ? 0 : getDiscountAmount(discount);
          })
          ?.reduce((acc = 0, curr) => {
            return (acc += +curr);
          })
      : 0;

    return totalDiscountAmount;
  };

  function getDiscountAmount(additionalDiscount) {
    const {
      percent,
      applied_on_total_premium: applied_on_total_cart_premium,
      applied_on_riders,
      fixed_discount_value,
      applied_on_discounts,
      applied_on_base_premium,
    } = additionalDiscount;

    let discountAmount = 0;

    if (fixed_discount_value) {
      //? return the amount directly if it is fixed.
      return +fixed_discount_value;
    }

    if (applied_on_base_premium) {
      return (+cartEntry?.premium * +percent) / 100;
    }

    if (applied_on_total_cart_premium) {
      //? Means applied on cart total premium.
      //? Return discount amount applied on total_cart_premium.
      const discount = (+cartEntry?.netPremiumWithoutDiscount * +percent) / 100;

      return (discountAmount = discountAmount + discount);
    }

    if (!applied_on_total_cart_premium) {
      //? means applied on premium
      const discount = (+total_premium * +percent) / 100;
      discountAmount = discountAmount + discount;
    }

    if (applied_on_riders) {
      const filtered_applied_on_riders = cartEntry.health_riders.filter(
        singleHealthRider =>
          applied_on_riders.includes(singleHealthRider?.alias),
      );

      const filtered_applied_on_riders_amount =
        filtered_applied_on_riders.length
          ? filtered_applied_on_riders
              ?.map(rider => rider.total_premium)
              ?.reduce((acc = 0, curr) => (acc += +curr))
          : 0;

      const discount = (+filtered_applied_on_riders_amount * +percent) / 100;

      discountAmount = discountAmount + discount;
    }

    if (applied_on_discounts) {
      const selectedAdditionalDiscount =
        getSelectedAdditionalDiscounts() || cartEntry?.additional_discounts;

      const filtered_applied_on_discounts = selectedAdditionalDiscount.filter(
        singleSelectedDiscount =>
          applied_on_discounts.includes(singleSelectedDiscount?.alias),
      );

      const filtered_applied_on_discounts_amount =
        filtered_applied_on_discounts?.length
          ? discountOnAnotherDiscount({
              discounts_on_which_discount_to_be_applied:
                filtered_applied_on_discounts,
              total_premium,
              premium,
              cartEntry,
            })
          : 0;

      const discount = (+filtered_applied_on_discounts_amount * +percent) / 100;

      discountAmount = discountAmount - discount;
    }

    return discountAmount;
  }

  function getSelectedAdditionalDiscounts() {
    if (queryState?.isLoading || queryState?.isError) return [];

    let selectedAdditionalDiscounts =
      data?.data?.filter(
        additionalDiscount =>
          !!discounts?.find(discount =>
            discount?.startsWith(additionalDiscount?.alias),
          ),
      ) || cartEntry?.additional_discounts;
    selectedAdditionalDiscounts = selectedAdditionalDiscounts?.map(discount => {
      const matchedAlias = discounts.find(discount_alias =>
        discount_alias.startsWith(discount?.alias),
      );

      if (typeof discount?.percent === "object") {
        const discountCode = matchedAlias?.split(":")[1];
        return {
          ...discount,
          percent: +discountCode.split("_")[1],
          selected_option: discountCode,
        };
      }
      return discount;
    });

    return selectedAdditionalDiscounts;
  }

  return {
    query: { ...queryState, data },
    getSelectedAdditionalDiscounts,
    toggleAdditionalDiscount,
    getDiscountAmount,
    addAdditionalDiscount,
    getTotalDiscountAmount,
    isAdditionalDiscountSelected,
  };
}

export const useAdditionalDiscountValidation = (
  groupCode = 263845,
  additionalDiscount = {},
) => {
  const { getCartEntry, updateCartEntry } = useCart();

  const cartEntry = getCartEntry(groupCode) || {};
  const [mandatory_discounts_alias_array, set_mandatory_discounts_alias_array] =
    useState([]);
  const { getSelectedRiders } = useRider(groupCode);

  const { is_mandatory } = additionalDiscount;

  //* Local function to the hook.
  const validateMandatoryDiscount = () => {
    if (is_mandatory) {
      if (additionalDiscount?.disable) {
        return {
          ...additionalDiscount?.disable,
          disable_due_to_mandatory_discount: true,
        };
      } else {
        return { disable_due_to_mandatory_discount: true };
      }
    }
    return { disable_due_to_mandatory_discount: false };
  };

  //* Local function to the hook.
  const validateOnlyAvailableWithRidersDiscount = () => {
    const selectedRiders = getSelectedRiders();
    const selectedRidersAliasArray = selectedRiders.map(rider => rider?.alias);
    if (additionalDiscount.only_available_with_riders) {
      const { only_available_with_riders } = additionalDiscount;
      return selectedRidersAliasArray.some(riderAlias => {
        return only_available_with_riders.includes(riderAlias);
      });
    } else {
      return true;
    }
  };

  let isDiscountAvailable = true;
  let disable = {};

  useEffect(() => {
    //* Auto-Select Mandatory Discount.
    if (additionalDiscount?.is_mandatory) {
      if (typeof additionalDiscount?.percent === "number") {
        set_mandatory_discounts_alias_array(current => [
          ...current,
          additionalDiscount?.alias,
        ]);
      } else if (typeof additionalDiscount?.percent === "object") {
        set_mandatory_discounts_alias_array(current => [
          ...current,
          `${additionalDiscount?.alias}:${
            Object.keys(additionalDiscount?.percent)[0]
          }`,
        ]);
      }
    }
    return () => {};
  }, [additionalDiscount?.is_mandatory, cartEntry]);

  useEffect(() => {
    updateCartEntry(groupCode, {
      discounts: [
        ...new Set([
          ...cartEntry?.discounts,
          ...mandatory_discounts_alias_array,
        ]),
      ],
    });
  }, [JSON.stringify(mandatory_discounts_alias_array)]);

  isDiscountAvailable = validateOnlyAvailableWithRidersDiscount();
  disable = validateMandatoryDiscount();

  const disableToken = Object.values(disable).some(value => value);

  return { isDiscountAvailable, disableToken, is_mandatory };
};

export const useDiscountOptions = ({ groupCode, discount }) => {
  const { percent } = discount;
  const { addAdditionalDiscount } = useAdditionalDiscount(groupCode);

  let defaultOption = "";

  if (typeof percent === "number") {
    defaultOption = percent;
  } else if (typeof percent === "object") {
    defaultOption = Object.keys(percent)[0];
  }

  const [selectedOptionCode, setSelectedOptionCode] = useState(defaultOption);

  const optionsChangeHandler = ({ selected_options_code, discount }) => {
    setSelectedOptionCode(selected_options_code);

    addAdditionalDiscount({
      ...discount,
      percent: +selected_options_code.split("_")[1],
      selected_option: selected_options_code,
    });
  };

  return { selectedOptionCode, optionsChangeHandler };
};

export function useToggle(initialValue = false) {
  const [isOn, setIsOn] = useState(initialValue);

  const on = () => setIsOn(true);
  const off = () => setIsOn(false);

  const toggle = () => setIsOn(!isOn);

  return { isOn, on, off, toggle };
}

export function useCreateEnquiry() {
  const { journeyType } = useFrontendBoot();

  const [createEnquiryMutation, queryState] = useCreateEnquiryMutation();

  const createEnquiry = enquiryData => {
    // delete null values from params
    delete enquiryData.params.null;
    return createEnquiryMutation({ section: journeyType, ...enquiryData });
  };

  return [createEnquiry, queryState];
}

export function useUrlEnquiry() {
  const urlQueryStrings = useUrlQuery();

  const enquiryId = urlQueryStrings.get("enquiryId");

  const { groupCode } = useParams();

  function getUrlWithEnquirySearch(path = "") {
    const currentGroup =
      localStorage.getItem("groups") &&
      JSON.parse(localStorage.getItem("groups")).find(
        group => group?.id === +groupCode,
      );
    const locationQuery =
      currentGroup?.pincode && currentGroup?.city
        ? `&pincode=${currentGroup.pincode}&city=${currentGroup?.city}`
        : "";

    return `${path}?enquiryId=${enquiryId}${locationQuery}`;
  }

  return { enquiryId, getUrlWithEnquirySearch };
}

/// condition fetching quotes query
export function useGetSingleQuote(queryConfig = {}) {
  const { groupCode } = useParams();
  const { journeyType } = useFrontendBoot();
  const { getSelectedFilter } = useFilters();

  const [getQuoteQuery, { data, isFetching, isLoading, refetch }] =
    useLazyGetQuoteQuery(undefined, {
      ...queryConfig,
      fetchPolicy: "cache-only",
    });

  function getQuote(filters) {
    const { sum_insured, insurerToFetch, deductible, tenure } = filters;

    return getQuoteQuery({
      insurer: insurerToFetch,
      deductible,
      sum_insured_range: sum_insured,
      group: +groupCode,
      base_plan_type: getSelectedFilter("baseplantype")?.code,
      tenure,
      plan_type: getSelectedFilter("plantype")?.code,
      journeyType,
    });
  }
  return {
    data: { data },
    isFetching,
    isLoading,
    refetch,
    getQuote,
  };
}

export function useGetQuotes(queryParams = {}, skipToken = true) {
  const { journeyType } = useFrontendBoot();

  const { getSelectedFilter } = useFilters();

  const { currentGroupObject } = useGetEnquiry();
  const { showEditMembers: skip } = useSelector(({ quotePage }) => quotePage);

  const urlQueryStrings = new URLSearchParams(window.location.search);

  let sharedInsurers = urlQueryStrings.get("insurers");

  if (sharedInsurers) {
    sharedInsurers = sharedInsurers.split(",");
  }

  const { filterQuotes } = useQuoteFilter({
    givenMoreFilters: {
      no_claim_bonus: getSelectedFilter("no_claim_bonus"),
      others: getSelectedFilter("others"),
      popular_filters: getSelectedFilter("popular_filters"),
      pre_existing_ailments: getSelectedFilter("pre_existing_ailments"),
    },
  });

  let { data, ...getCustomQuotesQuery } = useGetCustomQuotesQuery(
    {
      plan_type: currentGroupObject?.plan_type,
      group: currentGroupObject?.id,
      base_plan_type: journeyType === "health" ? "base_health" : "topup_plan",
      pincode: currentGroupObject?.pincode,
      tenure: 1,
      journeyType,
      ...queryParams,
    },
    {
      skip: skip || skipToken,
      refetchOnMountOrArgChange: true,
    },
  );
  let [triggerQuotesAPI, { data: lazyData }] = useLazyGetCustomQuotesQuery();

  const quotesWithoutMoreFilters = data;

  //? SUPPLIES FILTERED QUOTE [PREMIUM + MORE FILTERS]
  if (data) {
    data = data?.map(insurerQuotes => {
      return {
        ...insurerQuotes?.data,
        company_alias: insurerQuotes?.company_alias,
        data: {
          ...insurerQuotes,
          data: filterQuotes(insurerQuotes?.data?.data),
        },
      };
    });
  }
  // remove failed quote
  if (data) {
    data = data.filter(insurerQuotes => insurerQuotes?.data?.data !== null);
  }

  return {
    ...getCustomQuotesQuery,
    data,
    triggerQuotesAPI,
    quotesWithoutMoreFilters,
    lazyData,
  };
}

export function useInsurersToFetch() {
  const {
    data: {
      data: { groups },
    },
  } = useGetEnquiry();

  const { groupCode } = useParams();

  let filteredInsurers = [];

  let currentGroup = groups.find(group => group?.id === parseInt(groupCode));

  if (currentGroup) {
    const { extras } = currentGroup;
    if (extras && extras.insurers) filteredInsurers = extras.insurers;
  }

  const { companies: allInsurers } = useCompanies();

  const insurersToFetch = filteredInsurers.length
    ? filteredInsurers?.map(insurer => insurer.alias)
    : Object.keys(allInsurers);

  return insurersToFetch;
}

export function useQuotesCompare() {
  const dispatch = useDispatch();
  const compareQuotes = useSelector(state => state.utilities.compare.products);
  const isQuotesOnCompare = compareQuotes?.length > 0;

  const [updateCompareQuotesMutation, updateQuery] =
    useUpdateCompareQuotesMutation();

  const { data, ...query } = useGetCompareQuotesQuery(undefined, {
    skip: !allowOnSpecificPages(["/compare", "/productdetails"]),
  });

  useEffect(() => {
    if (compareQuotes && compareQuotes?.length > 0 && data !== undefined) {
      const quotes = data?.data?.products?.[0]?.quotes;
      dispatch(setCompareQuotesAction(quotes));
    }
    return () => {};
  }, [data]);

  function addQuote(quote) {
    if (compareQuotes?.length === 3) return;
    dispatch(setCompareQuotesAction([...compareQuotes, quote]));
  }

  function removeQuote(quote) {
    const updatedArray = compareQuotes?.filter(
      compareQuote =>
        !every([
          parseInt(compareQuote?.product.id) === parseInt(quote?.product.id),
          parseInt(compareQuote?.sum_insured) === parseInt(quote?.sum_insured),
          compareQuote?.deductible === quote?.deductible,
        ]),
    );
    dispatch(setCompareQuotesAction(updatedArray));
  }

  function removeCompareQuote({ quote: quoteToRemove, groupCode }) {
    dispatch(
      api.util.updateQueryData("getCompareQuotes", undefined, draft => {
        Object.assign(draft, {
          data: {
            products: draft.data.products?.map(compare => {
              return parseInt(compare.group) === parseInt(groupCode)
                ? {
                    ...compare,
                    quotes: compare.quotes?.filter(
                      quote =>
                        !every([
                          parseInt(quoteToRemove.product.id) ===
                            parseInt(quote.product.id),
                          parseInt(quoteToRemove.sum_insured) ===
                            parseInt(quote.sum_insured),
                          quoteToRemove.deductible === quote.deductible,
                        ]),
                    ),
                  }
                : compare;
            }),
          },
        });
      }),
    );
  }

  function isCompareQuote(quote) {
    return compareQuotes?.some(compareQuote =>
      every([
        compareQuote?.product?.id === quote?.product?.id,
        +compareQuote?.sum_insured === +quote?.sum_insured,
        quote?.deductible
          ? parseInt(compareQuote?.deductible) === parseInt(quote?.deductible)
          : true,
      ]),
    );
  }

  function reset() {
    dispatch(setCompareQuotesAction([]));
  }

  function getCompareQuotes(groupCode) {
    if (!data?.data) return;

    const { products } = data.data;

    if (!products) return;

    return products.find(product => product.group === parseInt(groupCode));
  }

  function updateCompareQuote({ updatedQuote, previousQuote, groupCode }) {
    dispatch(
      api.util.updateQueryData("getCompareQuotes", undefined, draft => {
        Object.assign(draft, {
          data: {
            products: draft.data.products?.map(compare => {
              return compare.group === parseInt(groupCode)
                ? {
                    ...compare,
                    quotes: compare.quotes?.map(quote => {
                      return matchQuotes(quote, previousQuote)
                        ? updatedQuote
                        : quote;
                    }),
                  }
                : compare;
            }),
          },
        });
      }),
    );
  }

  function getUpdateCompareQuotesMutation(groupCode) {
    function updateCompareQuotes(quotes = []) {
      if (quotes?.length) {
        if (!data?.data?.products)
          return updateCompareQuotesMutation({
            products: [{ group: parseInt(groupCode), quotes }],
          });

        const { products } = data.data;

        return updateCompareQuotesMutation({
          products: products?.map(product => {
            return parseInt(product.group) === parseInt(groupCode)
              ? { ...product, quotes }
              : product;
          }),
        });
      }
    }

    return [updateCompareQuotes, updateQuery];
  }

  return {
    addQuote,
    removeQuote,
    isCompareQuote,
    reset,
    getUpdateCompareQuotesMutation,
    getCompareQuotes,
    updateCompareQuote,
    removeCompareQuote,
    isQuotesOnCompare,
    quotes: compareQuotes,
    query,
  };
}

const validateEmail = (email = "") => /[\^!#$%^&*()<>?]/g.test(email);
export function useEmailInput(initialValue = "", setEmailError) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const { loginData } = useAutoBasicDetailsPass();

  useEffect(() => {
    loginData?.email && setValue(loginData?.email);
  }, [loginData]);

  const onChange = evt => {
    setEmailError({});
    setTouched(true);
    const { value: givenValue } = evt.target;

    if (!givenValue) {
      setValue(givenValue);
      return;
    }

    if (!validateEmail(givenValue)) {
      setValue(givenValue);
    }
  };

  return { value, onChange, touched };
}

const validateName = (name = "") => /^[a-zA-Z.\s]*$/.test(name);

export function useNameInput(initialValue = "", setFullNameError) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const { loginData } = useAutoBasicDetailsPass();

  useEffect(() => {
    loginData?.name && setValue(loginData?.name);
  }, [loginData]);

  const onChange = evt => {
    setTouched(true);
    setFullNameError({});
    const { value: givenValue } = evt.target;

    if (!givenValue) {
      setValue(givenValue);
      return;
    }

    const isValidName = validateName(givenValue);

    if (!isValidName) return;
    // checkPreviousChar(givenValue, ".", value) && setValue(givenValue);
    checkName(givenValue) && setValue(givenValue);
  };

  const onBlur = evt => {
    const { value: givenValue } = evt.target;
    setValue(capitalize(givenValue.trim()));
  };

  const style = { textTransform: "capitalize" };

  return { value, onChange, onBlur, style, touched };
}

const validateNumber = (str = "") => /^\d*$/.test(str);

const filterNo = (setNumber, number, value) => {
  if (number?.length === 0) {
    if (value >= 6 && value !== 0) {
      setNumber(value);
    }
  } else {
    setNumber(value);
  }
};

export function useNumberInput(
  initialValue = "",
  setNumberError,
  { maxLength = 60 } = {},
) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const { loginData } = useAutoBasicDetailsPass();

  useEffect(() => {
    loginData?.mobile && setValue(loginData?.mobile);
  }, [loginData]);

  const onChange = evt => {
    setTouched(true);
    setNumberError({});
    const { value: givenValue } = evt.target;

    if (givenValue.length > maxLength) return;

    if (!givenValue) {
      setValue(givenValue);
      return;
    }

    const isNumber = validateNumber(givenValue);

    if (isNumber) {
      filterNo(setValue, value, givenValue);
    }
  };
  return { value, onChange, type: "tel", maxLength, touched };
}

export function useQuotes({ sortBy = "relevence", quotesData = [] }) {
  let mergedQuotes = quotesData;

  const { data } = useGetEnquiry();
  const { journeyType } = useFrontendBoot();

  if (quotesData) {
    mergedQuotes = quotesData.filter(icQuotes => {
      return !!icQuotes?.data?.data?.[0]?.total_premium;
    });
    mergedQuotes = quotesData?.map(icQuotes => ({
      ...icQuotes,
      data: { data: mergeQuotes(icQuotes.data.data, { sortBy }) },
    }));
    if (sortBy === "premium_low_to_high") {
      mergedQuotes = mergedQuotes.filter(
        icQuotes => !!icQuotes?.data?.data[0]?.length,
      );

      mergedQuotes = mergedQuotes.sort((icQuotesA, icQuotesB) => {
        let ridersPremiumA = 0;
        let ridersPremiumB = 0;

        icQuotesA.data.data[0][0]?.[`${journeyType}_riders`].forEach(
          rider => (ridersPremiumA += rider?.total_premium),
        );
        icQuotesB.data.data[0][0]?.[`${journeyType}_riders`].forEach(
          rider => (ridersPremiumB += rider?.total_premium),
        );
        if (
          icQuotesA.data.data[0][0].total_premium + ridersPremiumA >
          icQuotesB.data.data[0][0].total_premium + ridersPremiumB
        ) {
          return 1;
        } else return -1;
      });
    } else if (sortBy === "premium_high_to_low") {
      mergedQuotes = mergedQuotes.filter(
        icQuotes => !!icQuotes?.data?.data[0]?.length,
      );

      mergedQuotes = mergedQuotes.sort((icQuotesA, icQuotesB) => {
        let ridersPremiumA = 0;
        let ridersPremiumB = 0;

        icQuotesA.data.data[0][0]?.[`${journeyType}_riders`].forEach(
          rider => (ridersPremiumA += rider?.total_premium),
        );
        icQuotesB.data.data[0][0]?.[`${journeyType}_riders`].forEach(
          rider => (ridersPremiumB += rider?.total_premium),
        );
        if (
          icQuotesA.data.data[0][0].total_premium + ridersPremiumA <
          icQuotesB.data.data[0][0].total_premium + ridersPremiumB
        ) {
          return 1;
        } else return -1;
      });
    } else if (
      sortBy === "relevance" &&
      data?.data?.input?.existing_diseases?.length
    ) {
      mergedQuotes = mergedQuotes.filter(
        icQuotes => !!icQuotes?.data?.data[0]?.length,
      );

      mergedQuotes = mergedQuotes.sort((icQuotesA, icQuotesB) => {
        if (
          +icQuotesA.data.data[0][0]?.features
            ?.find(f => f.code === "pre_existing_disease_cover")
            ?.value?.split(" ")[0] >
          +icQuotesB.data.data[0][0]?.features
            ?.find(f => f.code === "pre_existing_disease_cover")
            ?.value?.split(" ")[0]
        ) {
          return 1;
        } else return -1;
      });
    }
  }

  return { mergedQuotes };
}

// function getDeductibles(quotes = []) {
//   return uniq(quotes?.map(quote => quote.deductible));
// }

export function useQuoteCard({
  quotes: initialQuotes = [],
  sortBy,
  isProductDetailsPage = false,
}) {
  const { getSelectedFilter } = useFilters();

  const [quotesAndSelectedDeductible, setQuotesAndSelectedDeductible] =
    useState({
      quotes: initialQuotes,
      selectedDeductible: getSelectedFilter("deductible").code || 500000,
      selectedSumInsured: null,
    });

  //! if premium filter don't work use the filterQuotes method of useQuoteFilter below to filter quotes.
  //! and comment the below "If the initialQuotes changed then update the state."'s useEffect
  /*
  ..........................
  ..........................
  ..........................
  */

  useEffect(() => {
    //* If the initialQuotes changed then update the state.
    if (!isProductDetailsPage) {
      setQuotesAndSelectedDeductible({
        quotes: initialQuotes,
        selectedDeductible: getSelectedFilter("deductible").code,
        selectedSumInsured: null,
      });
    }
    return () => {};
  }, [JSON.stringify(initialQuotes)]);

  const { selectedDeductible, selectedSumInsured, quotes } =
    quotesAndSelectedDeductible;
  const isDeductibleJourney = quotes && quotes[0]?.deductible;

  const deductibles = [
    ...new Set(
      quotes &&
        quotes[0]?.available_sum_insured_deductibles
          ?.map(data => data.deductible)
          ?.sort((a, b) => a - b),
    ),
  ];

  const sumInsureds = isDeductibleJourney
    ? quotes &&
      quotes
        ?.filter(
          quote => parseInt(quote?.deductible) === parseInt(selectedDeductible),
        )
        ?.map(quote => parseInt(quote?.sum_insured))
        ?.sort((a, b) => a - b)
    : initialQuotes
        ?.map(quote => parseInt(quote?.sum_insured))
        .sort((a, b) => a - b);

  const setSelectedSumInsured = value => {
    setQuotesAndSelectedDeductible(prev => ({
      ...prev,
      selectedSumInsured: value,
    }));
  };

  const { getQuote, isLoading, isFetching } = useGetSingleQuote();

  const quote = quotes?.find(quote => {
    return isDeductibleJourney
      ? parseInt(quote.deductible) === parseInt(selectedDeductible) &&
          parseInt(quote.sum_insured) === parseInt(selectedSumInsured)
      : parseInt(quote.sum_insured) === parseInt(selectedSumInsured);
  });
  const { getCompany } = useCompanies();
  useEffect(() => {
    if (!quote) {
      setSelectedSumInsured(parseInt(sumInsureds && sumInsureds[0]));
    }
    return () => {};
  }, [quote, sumInsureds, deductibles]);

  const { logo: logoSrc } = getCompany(quote?.company_alias) || {};

  const handleSumInsuredChange = evt => {
    const { value } = evt;
    setSelectedSumInsured(parseInt(value));
  };

  const handleDeductibleChange = evt => {
    const { value } = evt;
    getQuote({
      insurerToFetch: quote?.company_alias,
      sum_insured: quote?.sum_insured,
      deductible: +value,
      tenure: quote?.tenure,
    })
      .unwrap()
      .then(data => {
        const mergedQuotes = mergeQuotes(data?.data, { sortBy })?.find(
          mq => mq[0]?.product?.id === quote?.product?.id,
        );

        const currentSumInsured = mergedQuotes
          ?.filter(quote => parseInt(quote?.deductible) === parseInt(value))
          ?.map(quote => parseInt(quote?.sum_insured))
          ?.sort((a, b) => a - b)[0];

        setQuotesAndSelectedDeductible(() => ({
          quotes: mergedQuotes,
          selectedDeductible: parseInt(value),
          selectedSumInsured: currentSumInsured,
        }));
      });
  };

  return {
    quote,
    logoSrc,
    handleSumInsuredChange,
    handleDeductibleChange,
    selectedDeductible,
    selectedSumInsured,
    deductibles,
    sumInsureds,
    isFetching,
    isLoading,
  };
}

function getCompareSlotReducer({ maxLength = 3 } = {}) {
  return function compareSlotReducer(currentSlot = [], action) {
    switch (action.type) {
      case "add":
        if (currentSlot.length < maxLength)
          return [...currentSlot, action.payload];
        return currentSlot;

      case "remove":
        return currentSlot.filter(
          quote => quote.product.id !== action.payload.product.id,
        );

      case "clear":
        return [];

      default:
        throw new Error(`Unhandled action type ${action.type}`);
    }
  };
}

export function useCompareSlot({ initialState = [], maxLength = 3 } = {}) {
  const [quotes, dispatch] = useReducer(
    getCompareSlotReducer({ maxLength }),
    initialState,
  );

  const add = quote => dispatch({ type: "add", payload: quote });
  const remove = quote => dispatch({ type: "remove", payload: quote });
  const check = quote =>
    quotes?.some(quoteInSlot => matchQuotes(quote, quoteInSlot));

  const clear = () => dispatch({ type: "clear" });

  return { quotes, add, remove, check, clear };
}
// export function useGetQuote(company_alias) {
//   const { showEditMembers: skip } = useSelector(({ quotePage }) => quotePage);
//   const { data } = useGetQuotes({ skip });

//   const icQuotes =
//     data &&
//     data.find(icQuotes => icQuotes?.data?.company_alias === company_alias);

//   const isLoading = !data || !icQuotes;

//   return { isLoading, data, icQuotes };
// }

export function useFeatureLoadHandler() {
  const [features, setFeatures] = useState({});

  const onLoad = ({ featureCode, feature }, quote) => {
    if (!feature?.feature_value) return;
    setFeatures(features => {
      const quoteKey = getQuoteKey(quote);

      const updatedFeatures = {
        ...features,
        [quoteKey]: features[quoteKey]
          ? { ...features[quoteKey], [featureCode]: feature }
          : { [featureCode]: feature },
      };

      return updatedFeatures;
    });
  };

  return { features, onLoad };
}

export function useCompareFeature(compareQuote) {
  let query = useGetCompareFeaturesQuery(compareQuote?.product?.id);
  let { data } = query;
  if (compareQuote?.product?.id && data) {
    sessionStorage.setItem(compareQuote?.product?.id, JSON.stringify(data));
  }
  function getFeature({ sectionTitle, featureCode }) {
    if (!data) return null;
    const compareFeature = data.find(feature => feature.name === sectionTitle);
    if (!compareFeature) return null;
    const features =
      compareFeature?.sum_insureds?.[compareQuote?.sum_insured]?.features;
    if (!features) return null;
    const feature = features?.find(feature => feature.code === featureCode);
    return feature;
  }
  return { getFeature, query };
}

export function useGetRiders(
  quote,
  groupCode,
  { queryOptions = {} } = {},
  skipToken,
) {
  const { getCartEntry } = useCart();

  const { additional_discounts } = getCartEntry(groupCode);

  const { journeyType, subJourneyType } = useFrontendBoot();

  const { isPortPlan } = usePortJourneyConfig();

  const getRidersQueryParams = {
    sum_insured: +quote?.sum_insured,
    tenure: +quote?.tenure,
    productId: +quote?.product?.id,
    group: +groupCode,
    journeyType,
    subJourneyType,
    isPortPlan: isPortPlan(groupCode),
    ...queryOptions,
    additional_discount_policy_number:
      additional_discounts?.[0]?.additional_discount_policy_number,
  };

  if (quote?.deductible) {
    getRidersQueryParams.deductible = +quote?.deductible;
  }

  return useGetRidersQuery(getRidersQueryParams, {
    skip: skipToken,
  });
}

function isMandatoryRider(rider) {
  return !!rider.is_mandatory;
}

export function getSelectedRiders(riders = []) {
  const selectedRiders = riders.filter(
    rider => !!(isMandatoryRider(rider) || rider.isSelected),
  );

  return selectedRiders;
}

export const useRevisedPremiumModal = () => {
  const { proposalData } = useSelector(state => state.proposalPage);
  const { journeyType } = useFrontendBoot();
  const { cartEntries, getCartTotalPremium, getCartEntry } = useCart();

  const [getLazyCart] = useLazyGetCartQuery();

  const revisedPremiumPopupToggle = useToggle();

  const { groupCode } = useParams();

  const [revisedPremiumCheckHitByUs, setRevisedPremiumCheckHitByUs] =
    useState(false);

  const isProductDetailsPage = useRouteMatch({ path: "/productdetails" });

  const isProposalPage = useRouteMatch({ path: "/proposal" });

  const dispatch = useDispatch();

  const { data: enquiryData } = useGetEnquiry();

  const firstName =
    proposalData?.["Proposer Details"]?.name ||
    enquiryData?.data?.name?.split(" ")[0];

  /*-----------------------------------------------------------------------------------------------*/
  //? Proposal page constants

  const prevTotalPremium = useMemo(() => {
    return getCartTotalPremium();
  }, []);

  const previousCartEntries = useMemo(() => {
    return cartEntries;
  }, []); /* memorizes the first value it gets */

  const updatedTotalPremium =
    getCartTotalPremium(); /* Gets the updated value each time */

  /*-----------------------------------------------------------------------------------------------*/
  //? Product Details page constants

  const prevPremium = useMemo(() => {
    return getCartEntry(groupCode)?.premium;
  }, [groupCode]); /* memorizes the first value it gets */

  const updatedPremium = getCartEntry(groupCode)?.premium;

  /*-----------------------------------------------------------------------------------------------*/

  const getUpdatedCart = (next = () => {}) => {
    dispatch(
      api.util.invalidateTags(
        isProductDetailsPage
          ? ["Rider", "AdditionalDiscount", "TenureDiscount", "featureOption"]
          : ["Cart"],
      ),
    );
    setRevisedPremiumCheckHitByUs(true);
    next();
  }; /* Performs refetch from the server */

  const isAnyPlanUnAvailableInCart = cartEntries?.some(
    singleEntry => !!singleEntry?.unavailable_message,
  );

  const unAvailablePlanInTheCart = cartEntries?.find(
    singleEntry => !!singleEntry?.unavailable_message,
  );

  useEffect(() => {
    if (isProductDetailsPage) {
      //? PRODUCT DETAILS PAGE LOGIC

      if (+prevPremium === +updatedPremium) {
        revisedPremiumPopupToggle.off();
      }
      if (Math.abs(prevPremium - updatedPremium) > 10) {
        revisedPremiumPopupToggle.on();
        dispatch(setIsPopupOn(true));
      }
    } else {
      //? PROPOSAL PAGE LOGIC

      if (+prevTotalPremium === +updatedTotalPremium) {
        revisedPremiumPopupToggle.off();
      }

      // if (+prevTotalPremium !== +updatedTotalPremium) {
      if (Math.abs(prevTotalPremium - updatedTotalPremium) > 10) {
        let stringedRidersName = "";
        for (let i = 0; i < previousCartEntries.length; i++) {
          const previousEntry = previousCartEntries[i];
          const currentEntry = cartEntries.find(
            entry => entry.id === previousEntry.id,
          );
          let ridersInPreviousCart = previousEntry?.[
            `${journeyType}_riders`
          ]?.map(rider => rider.name);
          let ridersInCurrentCart = currentEntry?.[
            `${journeyType}_riders`
          ]?.map(rider => rider.name);

          if (ridersInPreviousCart?.length !== ridersInCurrentCart?.length) {
            let removedRiderName = ridersInPreviousCart?.find(
              rider => ridersInCurrentCart?.indexOf(rider) < 0,
            );
            stringedRidersName += !stringedRidersName
              ? removedRiderName
              : ` and ${removedRiderName}`;
          }
        }
        if (stringedRidersName && stringedRidersName !== "undefined") {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: `Based on changes in Insured Date of Birth ${stringedRidersName} is unavailable. Please click OK & proceed.`,
              onCloseCallBack: () => {
                revisedPremiumPopupToggle.on();
                dispatch(setIsPopupOn(true));
              },
            }),
          );
        } else {
          revisedPremiumPopupToggle.on();
          dispatch(setIsPopupOn(true));
        }
      }
    }
    return () => {};
  }, [prevTotalPremium, updatedTotalPremium, prevPremium, updatedPremium]); //? CONTROLS DISPLAY OF REVISED PREMIUM POPUP DIFFERENCE IN AMOUNT

  useEffect(() => {
    if (isAnyPlanUnAvailableInCart) {
      setRevisedPremiumCheckHitByUs(true);
      revisedPremiumPopupToggle.on();
      dispatch(setIsPopupOn(true));
    }
    return () => {};
  }, [isAnyPlanUnAvailableInCart]); //? CONTROLS DISPLAY OF REVISED PREMIUM POPUP IN CASE OF UNAVAILABILITY OF PLAN

  const getUpdatedCartEntry = groupCode => {
    const cartEntry = cartEntries.find(
      cartEntry => +cartEntry?.group?.id === parseInt(groupCode),
    );

    return cartEntry;
  };

  const onOpenModal = () => {
    revisedPremiumPopupToggle.on();
  };

  const onCloseModal = () => {
    setRevisedPremiumCheckHitByUs(false);
    revisedPremiumPopupToggle.off();
  };

  const getPreviousCartEntryPremium = groupCode => {
    const cartEntry = previousCartEntries?.find(
      cartEntry => +cartEntry?.group?.id === parseInt(groupCode),
    );

    return cartEntry?.premium;
  };

  const getUpdatedCartEntryPremium = groupCode => {
    const cartEntry = cartEntries?.find(
      cartEntry => +cartEntry?.group?.id === parseInt(groupCode),
    );

    return cartEntry?.premium;
  };

  const titleGenerator = groupCode => {
    let title = `Hi ${firstName} , please note this plan is unavailable for the reason stated below.`;

    if (isProductDetailsPage) {
      title = getUpdatedCartEntry(groupCode)?.unavailable_message
        ? getUpdatedCartEntry(groupCode)?.unavailable_title
          ? `Hi ${firstName} , ${
              getUpdatedCartEntry(groupCode)?.unavailable_title
            }`
          : `Hi ${firstName} , please note this plan is unavailable for the reason stated below.`
        : `Hi ${firstName} , Revised Premium due to change in date of birth or medical declaration.`;
    }
    if (isProposalPage) {
      title = isAnyPlanUnAvailableInCart
        ? unAvailablePlanInTheCart?.unavailable_title
          ? `Hi ${firstName} , ${unAvailablePlanInTheCart?.unavailable_title}`
          : `Hi ${firstName} , please note this plan is unavailable for the reason stated below.`
        : `Hi ${firstName} , Revised Premium due to change in date of birth or medical declaration `;
    }
    return title;
  };

  const title = titleGenerator(groupCode);

  return {
    getUpdatedCart,
    revisedPremiumPopupToggle,
    prevTotalPremium,
    updatedTotalPremium,
    updatedCartEntries: cartEntries,
    on: onOpenModal,
    off: onCloseModal,
    isOnProductDetails:
      revisedPremiumCheckHitByUs && revisedPremiumPopupToggle.isOn,
    isOnProposal: revisedPremiumPopupToggle.isOn,
    getUpdatedCartEntry,
    getPreviousCartEntryPremium,
    getUpdatedCartEntryPremium,
    isAnyPlanUnAvailableInCart,
    unAvailablePlanInTheCart,
    title,
    getLazyCart,
  };
};

export const useDD = ({ initialValue = {}, required, errorLabel }) => {
  const [value, setValue] = useState(initialValue);

  const [isValueInputTouched, setIsValueInputTouched] = useState(false);

  const [error, setError] = useState({});

  const isValueValid = !error?.message;

  const showError = isValueInputTouched && !isValueValid;

  const ddErrorThrowingValidations = useCallback(
    (value, setError) => {
      //? Only validates if required.
      if (required) {
        if (!Object.keys(value).length) {
          return setError({ message: `Please select a ${errorLabel}.` });
        }
        return setError({});
      }
      return setError({});
    },
    [value],
  );

  useEffect(() => {
    ddErrorThrowingValidations(value, setError);
  }, [value, setError, ddErrorThrowingValidations]);

  const valueInputTouchedHandler = () => setIsValueInputTouched(true);

  const valueChangeHandler = singleOption => {
    const updatedValue = {
      code: singleOption?.value,
      display_name: singleOption?.label,
      ...singleOption,
    };
    setValue(updatedValue);
  };

  return {
    value,
    error,
    showError,
    isValueValid,
    shouldShowError: valueInputTouchedHandler,
    onChange: valueChangeHandler,
  };
};

export const usePolicyNumberValidations = ({
  initialValue = "",
  required,
  errorLabel,
  providedRegex = /^[\S]*$/,
}) => {
  const [value, setValue] = useState(initialValue);

  const [isValueInputTouched, setIsValueInputTouched] = useState(false);

  const [error, setError] = useState({});

  const isValueValid = !error?.message;

  const showError = isValueInputTouched && !isValueValid;

  const ddErrorThrowingValidations = useCallback(
    (value, setError) => {
      //? Only validates if required.
      if (required) {
        if (value === "") {
          return setError({ message: `Please select a ${errorLabel}.` });
        }
        if (!providedRegex.test(value)) {
          return setError({ message: `Please enter a valid ${errorLabel}.` });
        }
        return setError({});
      }
      return setError({});
    },
    [value],
  );

  useEffect(() => {
    ddErrorThrowingValidations(value, setError);
  }, [value, setError, ddErrorThrowingValidations]);

  const valueInputTouchedHandler = () => setIsValueInputTouched(true);

  const valueChangeHandler = e => {
    setValue(e.target.value);
  };

  return {
    value,
    error,
    showError,
    isValueValid,
    shouldShowError: valueInputTouchedHandler,
    onChange: valueChangeHandler,
  };
};

export const useClaimBanner = () => {
  const { settings } = useFrontendBoot();
  const { isPosJourney } = useIsPos();

  const claimBannerArray = isPosJourney
    ? settings?.claim_process_pos
    : settings?.claim_process;

  return {
    claimBannerArray,
    shouldShowClaimBanner: claimBannerArray ? true : false,
  };
};

export const useRenewalsConfig = () => {
  const { getCompany } = useCompanies();

  const { subJourneyType } = useFrontendBoot();

  const { data: enquiryData } = useGetEnquiry();

  const allowModification = (comp_alias = "") => {
    return !!getCompany(comp_alias)?.allows_proposal_updation_on_renewal;
  };

  const renewalExtras = enquiryData?.data?.renewal_policy?.extra;

  const allowsQuickPay = (initialSumInsured, selectedSumInsured) => {
    let showQuickPay = true;
    if (renewalExtras && initialSumInsured) {
      if (renewalExtras?.is_medical_history_on_si_change) {
        showQuickPay = initialSumInsured === selectedSumInsured;
      }
    }
    return (
      !!enquiryData?.data?.renewal_policy?.allows_quick_pay && showQuickPay
    );
  };

  const isRenewalsJourney = subJourneyType === "renewal";

  return {
    allowModification,
    allowsQuickPay,
    isRenewalsJourney,
  };
};

//? For all the functionalities that require groupCode in advance while using hook.
export const usePortabilityJourneyConfig = (groupCode = "") => {
  //* cartEntry?.supports_port : tell if a plan is being continued as port plan right from the quote page.
  //* cartEntry?.product?.supports_port : tell if a plan has portability journey support so i can be switched to port on product details page.

  const { updateCart, getCartEntry } = useCart();

  const { isPortPlan } = usePortJourneyConfig();

  const [updateCartMutation, updateCartQuery] = updateCart(groupCode);

  const { journeyType, subJourneyType } = useFrontendBoot();

  const cartEntry = getCartEntry(groupCode) || {};

  const { updateEnquiry, ...updateEnquiryQuery } = useUpdateEnquiry();

  const { getTotalDiscountAmount } = useAdditionalDiscount(groupCode);

  const [isPortCheckboxChecked, setIsPortCheckboxChecked] = useState(
    isPortPlan(groupCode),
  );

  useEffect(() => {
    if (groupCode) {
      setIsPortCheckboxChecked(isPortPlan(groupCode));
    }
    return () => {};
  }, [groupCode]);

  //* Port Date picker model Product details page.
  const [configurationModalState, setConfigurationModalState] = useState({
    show: false,
    type: "",
  });

  const portClickHandler = checked => {
    const supports_port = checked ? 1 : 0;

    const discounted_total_premium = getTotalPremiumWithDiscount({
      netPremiumWithoutDiscount: cartEntry?.netPremiumWithoutDiscount,
      totalDiscountAmount: getTotalDiscountAmount(),
    });

    const feature_options = featureOptionsValidValue(
      cartEntry?.feature_options,
    );

    updateCartMutation({
      discounted_total_premium: discounted_total_premium,
      feature_options: feature_options,
      additionalArgs: { supports_port },
    })
      .then(res => {
        if (res.error) return;

        if (checked) {
          setIsPortCheckboxChecked(true);
          setConfigurationModalState({
            show: true,
            type: "port_expiry_date",
          });
        } else {
          setIsPortCheckboxChecked(false);

          setConfigurationModalState({
            show: false,
            type: "",
          });

          return updateEnquiry({
            groupCode: groupCode,
            port_expiry_date: null,
          });
        }
      })
      .then(() => {});
  };

  const checkBoxLoading =
    updateCartQuery?.isLoading || updateEnquiryQuery?.isLoading;

  const disablePortCheckBox =
    journeyType === "top_up" || subJourneyType === "port";

  return {
    portClickHandler,
    isPortCheckboxChecked,
    checkBoxLoading,
    disablePortCheckBox,
    configurationModalState,
    setConfigurationModalState,
  };
};

//? For all the functionalities that don not require groupCode in advance while using hook.
export const usePortJourneyConfig = () => {
  const { groups, getGroup } = useMembers();

  const { journeyType, policyType, subJourneyType } = useFrontendBoot();

  const { getCartEntry, cartEntries } = useCart();

  //* Use after quote page
  function isPortPlan(groupCode) {
    return !!getCartEntry(groupCode)?.supports_port;
  }

  //* Use before product-details page
  function isPortGroup(groupCode) {
    return !!getGroup(groupCode)?.port_expiry_date;
  }

  function groupHasPortExpiryDate(groupCode) {
    const group = getGroup(groupCode);
    if (!group) return;

    return !!group?.port_expiry_date;
  }

  const shouldShowEditPolicyExpiryDateSection = groups?.some(grp =>
    groupHasPortExpiryDate(+grp?.id),
  );

  function planHasPortSupport(cartEntry = {}) {
    //* The supports_port key in products object of cartEntry indicates whether a product has port support or not.
    return !!cartEntry?.product?.supports_port;
  }

  function planTypeDecider(groupCode) {
    return isPortPlan(groupCode)
      ? "Port"
      : journeyType === "top_up"
      ? "Top Up"
      : "Base Health";
  }

  function groupsHasPortGroup() {
    return groups.some(group => !!group?.port_expiry_date);
  }

  function cartItemsHavePortPlan() {
    return cartEntries.some(cartEntry => !!cartEntry?.supports_port);
  }

  const showPortSectionOnEditMembers =
    policyType === "portability" || subJourneyType === "port";

  //! This must always be true in port journey.
  const defaultPortJourney = subJourneyType === "port";

  return {
    isPortPlan,
    isPortGroup,
    shouldShowEditPolicyExpiryDateSection,
    groupHasPortExpiryDate,
    planHasPortSupport,
    planTypeDecider,
    groupsHasPortGroup,
    cartItemsHavePortPlan,
    showPortSectionOnEditMembers,
    defaultPortJourney,
  };
};

export function useShowPosPopup(quote) {
  const configuration = quote?.product?.configuration;

  const {
    data: {
      settings: { pos_nonpos_switch_message },
    },
  } = useFrontendBoot();
  const { isPosJourney } = useIsPos();

  const isProductSellPos = configuration?.npos_product_sell_as_pos;

  return {
    showPopup: isPosJourney && isProductSellPos && pos_nonpos_switch_message,
    message: pos_nonpos_switch_message,
  };
}

export function useRedirectLink() {
  const {
    data: {
      tenant: {
        topup_frontend_domain,
        frontend_domain,
        extras,
        health_port_frontend_domain,
      },
    },
  } = useFrontendBoot();

  const generateLink = (
    enqID,
    newGroupCode,
    journeyType,
    subJourneyType = "new",
  ) => {
    if (brokerTenant("bajaj") && !process.env.REACT_APP_BLOCK_JOURNEY) {
      let healthURL = `${window.location.origin + "/general-insurance/health"}`;
      let topupURL = `${window.location.origin + "/general-insurance/top-up"}`;
      let portUrl = `${
        window.location.origin + "/general-insurance/portability"
      }`;
      const domain_link =
        journeyType === "top_up"
          ? topupURL
          : subJourneyType === "port"
          ? portUrl
          : healthURL;
      window.location.href = `${domain_link}/quotes/${newGroupCode}?enquiryId=${enqID}`;
      return;
    }

    const healthUrl = extras?.health_new_url_segment
      ? `${frontend_domain}/${extras?.health_new_url_segment}`
      : frontend_domain;
    const topupUrl = extras?.top_up_new_url_segment
      ? `${frontend_domain}/${extras?.top_up_new_url_segment}`
      : topup_frontend_domain;

    const portUrl = health_port_frontend_domain;
    const domain_link =
      journeyType === "top_up"
        ? topupUrl
        : subJourneyType === "port"
        ? portUrl
        : healthUrl; // build condition as per requirements

    window.location.href = `https://${domain_link}/quotes/${newGroupCode}?enquiryId=${enqID}`;
  };
  return {
    generateLink,
  };
}

export const useFeatureOptions = gp => {
  let { groupCode } = useParams();

  groupCode = groupCode || gp;

  const { getCartEntry, updateCartEntry } = useCart();

  const cartEntry = getCartEntry(groupCode);

  const skipToken = !groupCode || !cartEntry;

  const { featureDependentRiders } = useSelector(
    ({ productPage }) => productPage,
  );

  const { isLoading, isError, data, isFetching, refetch } =
    useGetFeatureOptionsQuery(
      {
        productId: cartEntry?.product?.id,
        sumInsured: cartEntry?.sum_insured,
        tenure: cartEntry?.tenure,
        groupCode,
        featureDependentRiders,
      },
      { skip: skipToken },
    );

  let feature_options = data?.data;

  const { getTotalDiscountAmount } = useAdditionalDiscount(groupCode);

  const netPremiumWithoutDiscount = cartEntry?.netPremiumWithoutDiscount || 0;

  const { getSelectedRiders } = useRider(groupCode);

  const getSelectedFeatureOptions = groupCode => {
    const cartFeatureOptions = getCartEntry(groupCode)?.feature_options || {};
    return featureOptionsValidValue(cartFeatureOptions);
  };

  const getSelectedFeatureValues = groupCode => {
    const cartFeatureOptions = getCartEntry(groupCode)?.feature_value || {};
    return featureOptionsValidValue(cartFeatureOptions);
  };

  const getSelectedFeatureOptionsAlias = groupCode => {
    const cartFeatureOptions = getCartEntry(groupCode)?.feature_options || {};

    const featureOptionsValue = featureOptionsValidValue(cartFeatureOptions);
    return Object.keys(featureOptionsValue).map(code =>
      code.split("_").slice(1).join("_"),
    );
  };

  const getSelectedFeatureOptionsObjects = groupCode => {
    const selectedFeaturesAliasArray =
      getSelectedFeatureOptionsAlias(groupCode);
    return feature_options.filter(feature_option =>
      selectedFeaturesAliasArray.includes(feature_option?.alias),
    );
  };

  const getMandatoryFeatureOption = () => {
    const mandatoryFeatures = feature_options?.filter(
      feature => feature.is_mandatory,
    );
    const selectedFeatureAlias = mandatoryFeatures?.map(
      feature => feature?.alias,
    );

    if (mandatoryFeatures && mandatoryFeatures?.length !== 0) {
      let mandatoryFeatureOptions = {};
      mandatoryFeatures?.forEach(feature => {
        const featureOption = Object.keys(feature?.options)[0];
        const featureOptionValue = Object.keys(
          feature?.options[featureOption],
        )[0];
        const selectedFeatureValue =
          cartEntry?.feature_options[`feature_${featureOption}`];
        mandatoryFeatureOptions = {
          ...mandatoryFeatureOptions,
          [`feature_${featureOption}`]:
            selectedFeatureValue || featureOptionValue,
        };
      });

      return { mandatoryFeatureOptions, selectedFeatureAlias };
    }
    return {};
  };

  const updateFeatureOptions = ({ feature_options = {} }) => {
    const discounted_total_premium = getTotalPremiumWithDiscount({
      netPremiumWithoutDiscount,
      totalDiscountAmount: getTotalDiscountAmount() || 0,
    });

    //? runs when feature options selected or unselected.

    updateCartEntry(groupCode, {
      ...cartEntry,
      discounted_total_premium,
      feature_options,
    });
  };

  feature_options = validateFeatureOptions({
    feature_options,
    selectedRiders: getSelectedRiders(),
    selectedFeatureOptions: getSelectedFeatureOptions(groupCode),
  });
  return {
    isLoading,
    isFetching,
    isError,
    feature_options,
    refetch,
    updateFeatureOptions,
    getSelectedFeatureOptions,
    getSelectedFeatureValues,
    getSelectedFeatureOptionsObjects,
    getSelectedFeatureOptionsAlias,
    getMandatoryFeatureOption,
  };
};

function validateFeatureOptions({
  feature_options = [],
  selectedRiders = [],
  selectedFeatureOptions = {},
}) {
  let featureOptions = feature_options;
  featureOptions = validateNotAvailableWithRidersFeatureOptions({
    feature_options: featureOptions,
    selectedRiders,
  });

  featureOptions = validateNotAvailableWithFeatureOption({
    feature_options: featureOptions,
    selectedFeatureOptions,
  });

  featureOptions = validateSelectedFeatureOptions({
    feature_options: featureOptions,
    selectedFeatureOptions,
  });
  return featureOptions;
}

const validateNotAvailableWithRidersFeatureOptions = ({
  feature_options = [],
  selectedRiders = [],
}) => {
  const selectedRidersAlias = selectedRiders?.map(rider => rider.alias) || [];

  return feature_options.map(feature => {
    if (feature?.not_available_with_rider) {
      return feature?.not_available_with_rider?.some(rider_alias =>
        selectedRidersAlias.includes(rider_alias),
      )
        ? {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with_rider`]: true,
            },
          }
        : {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with_rider`]: false,
            },
          };
    } else {
      return {
        ...feature,
        disable: {
          ...feature?.disable,
          [`disable_due_to_not_available_with_rider`]: false,
        },
      };
    }
  });
};

const validateNotAvailableWithFeatureOption = ({
  feature_options = [],
  selectedFeatureOptions = {},
}) => {
  //? not_available_with : the feature option that cannot be selected with another feature option.

  const selectedFeatureOptionsAliasArray = Object.keys(
    selectedFeatureOptions,
  ).map(code => code.split("_").slice(1).join("_"));
  return feature_options.map(feature => {
    if (feature?.not_available_with && feature?.not_available_with_option) {
      return feature?.not_available_with?.some(feature_alias =>
        selectedFeatureOptionsAliasArray?.includes(feature_alias),
      ) &&
        feature?.not_available_with_option?.some(featureOption =>
          Object.keys(selectedFeatureOptions)?.some(
            eachFeatureOptionKey =>
              selectedFeatureOptions[eachFeatureOptionKey] === featureOption,
          ),
        )
        ? {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with_option`]: true,
            },
          }
        : {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with_option`]: false,
            },
          };
    } else if (feature?.not_available_with) {
      return feature?.not_available_with?.some(feature_alias =>
        selectedFeatureOptionsAliasArray.includes(feature_alias),
      )
        ? {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with`]: true,
            },
          }
        : {
            ...feature,
            disable: {
              ...feature?.disable,
              [`disable_due_to_not_available_with`]: false,
            },
          };
    } else {
      return {
        ...feature,
        disable: {
          ...feature?.disable,
          [`disable_due_to_not_available_with`]: false,
        },
      };
    }
  });
};

const validateSelectedFeatureOptions = ({
  feature_options = [],
  selectedFeatureOptions = {},
}) => {
  const selectedFeatureOptionsCodeArray = Object.keys(selectedFeatureOptions);

  const selectedFeatureOptionsAliasArray = selectedFeatureOptionsCodeArray.map(
    featureCode => featureCode.split("_").slice(1).join("_"),
  );

  return feature_options.map(feature => {
    return selectedFeatureOptionsAliasArray.includes(feature?.alias)
      ? { ...feature, isSelected: true }
      : { ...feature, isSelected: false };
  });
};

function isQueryLoading(query) {
  return _.some([query.isUninitialized, query.isLoading, query.isFetching]);
}

export function useTotalPremiumLoader(cartEntry) {
  const { group } = cartEntry || {};

  const tenureDiscount = useTenureDiscount(group?.id);

  const isTotalPremiumLoading = _.some([isQueryLoading(tenureDiscount?.query)]);

  return isTotalPremiumLoading;
}

export const useErrorPopup = () => {
  const { errorPopup } = useSelector(({ error }) => error);

  const { type = "offline", isOn } = errorPopup;

  const dispatch = useDispatch();

  window.addEventListener("offline", () => {
    if (
      process.env.REACT_APP_PHASE === "UAT" &&
      process.env.REACT_APP_TENANT === "renew_buy"
    ) {
      return;
    } else {
      return dispatch(showErrorPopup({ type: "offline" }));
    }
  });

  return {
    type,
    isOn,
  };
};

export const useRenewalDateInput = ({
  isShown = false,
  defaultDateObj = new Date(),
  field = "dob",
}) => {
  const currentDateINMs = new Date().getTime();
  const [dateObj, setDateObj] = useState(defaultDateObj);

  const getDateErrors = dateObj => {
    if (dateObj === null) {
      return {
        message: "This field is required.",
      };
    }
    if (
      isNaN(dateObj.getDate()) ||
      isNaN(dateObj.getMonth()) ||
      isNaN(dateObj.getFullYear())
    ) {
      return {
        message: "Invalid date.",
      };
    }
    if (field === "dob") {
      const selectedDateInMs = dateObj.getTime();

      return selectedDateInMs > currentDateINMs
        ? {
            message: "Future Date of Birth not allowed.",
          }
        : {
            message: "",
          };
    }
    if (field === "expiry") {
      const oneDayBeforeCurrentDateObj = pastDateValidator(new Date(), {
        days: 31,
        months: 0,
        years: 0,
      });

      const expiryDateObj = futureDateValidator(new Date(), {
        days: 0,
        months: 3,
        years: 0,
      });

      const selectedDateString = `${dateObj.getFullYear()}-${
        dateObj.getMonth() + 1
      }-${dateObj.getDate()}`;

      const oneDayBeforeSelectedDateString = `${oneDayBeforeCurrentDateObj.getFullYear()}-${
        oneDayBeforeCurrentDateObj.getMonth() + 1
      }-${oneDayBeforeCurrentDateObj.getDate()}`;

      const expiryDateString = `${expiryDateObj.getFullYear()}-${
        expiryDateObj.getMonth() + 1
      }-${expiryDateObj.getDate()}`;

      const isSelectedDateValid = moment(selectedDateString).isBetween(
        oneDayBeforeSelectedDateString,
        expiryDateString,
      );

      if (!isSelectedDateValid) {
        return {
          message: "Invalid expiry date.",
        };
      } else {
        return {
          message: "",
        };
      }
    }
    return {
      message: "",
    };
  };

  return {
    show: isShown,
    dateObj,
    setDateObj,
    isDateValid: isShown ? getDateErrors(dateObj)?.message === "" : true,
    error: getDateErrors(dateObj),
  };
};

export const useTimer = ({ seconds = 1, gap = 0 }) => {
  const [count, setCount] = useState(seconds);
  const timerDone = count === 0;

  useEffect(() => {
    let timer;

    if (count > 0) {
      timer = setTimeout(() => {
        setCount(prev => Math.max(prev - 1, 0)); // Ensure the countdown doesn't go below zero
      }, gap * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [count, gap]);

  return { count, timerDone };
};

export const useShowWarningPopup = quote => {
  const { settings } = useFrontendBoot();

  const { showPosPopup, message: posMessageHtml } = useShowPosPopup(quote);

  const { medical_nstp_declaration_message_pos } = settings;

  const configuration = quote?.configuration || {};

  const nstp = !!configuration?.nstp;

  const showWarningPopup = nstp || showPosPopup;

  let messageHtml = "<p></p>";

  if (nstp) {
    messageHtml = medical_nstp_declaration_message_pos;
  } else if (showPosPopup) {
    messageHtml = posMessageHtml;
  }

  return {
    showWarningPopup,
    messageHtml,
  };
};
