import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// REACT ICONS
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
// CUSTOM HOOKS
import useIsPos from "src/pos/useIsPos";
import { useTheme, useUpdateEnquiry } from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import useSeeDetailsTab from "src/pages/SeeDetailsModal/Hooks/useSeeDetailsTab";
// UTILS
import { removeHtmlTagsFromString } from "src/utils/string.utility";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  DetailsSectionWrap,
  LeftButton,
  SeeDetailsModalBodyWrapperStyled,
  SeeDetailsModalFooterWrapperStyled,
  SeeDetailsModalWrapperStyled,
  SeeDetailsTabsContainerStyled,
  SeeDetailsTabsWrapperStyled,
  SeeDetailsModalContainerStyled,
  RightButton,
  TabStyled,
  BackdropStyled,
  SeeDetailsModalHeaderWrapperStyled,
} from "./SeeDetailsModal.style";
// CUSTOM COMPONENTS
import {
  AboutCompany,
  AddOnCoverages,
  CashlessHospitals,
  ClaimProcess,
  PlanDetails,
} from "src/pages/SeeDetailsModal/components/Sections";
import MobileSeeDetailsFooter from "./components/MobileSeeDetailsFooter";
import MobileSeeDetailsHeader from "./components/SeeDetailsHeader/MobileSeeDetailsHeader";
import DesktopSeeDetailsHeader from "./components/SeeDetailsHeader/DesktopSeeDetailsHeader";
// CONSTANTS
import { STORED_POS_COVER_MESSAGE } from "src/pos/utility";
import { PRODUCT_DETAILS_TABS } from "./Hooks/useSeeDetailsTab";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import usePosSwitch from "../NewQuotePage/Hooks/usePosSwitch";
import { brokerTenant } from "src/utils/tenantSettings";
import useFilters from "../NewQuotePage/QuoteFilters/filters/useFilters";
import useMultiYearFilter from "../NewQuotePage/Hooks/useMultiYearFilter";
import { useParams } from "react-router-dom";
import { useLazyGetQuoteQuery } from "src/api/api";
import toast from "react-hot-toast";

export default function SeeDetailsModal() {
  const { colors } = useTheme();

  const { handleOpenInfoPopup } = useInfoPopup();
  const { isPosJourney } = useIsPos();
  const {
    handleCloseSeeDetailsModal,
    seeDetailsCurrentQuote: initialQuote,
    seeDetailSection,
    seeDetailsModalOpen,
  } = useSeeDetailsUtility();

  const { journeyType = "health", settings } = useFrontendBoot();

  const { isLoading: isEnquiryLoading } = useUpdateEnquiry();

  // STATE/s
  const [quote, setQuote] = useState(initialQuote);
  const { ridersLoading } = useSelector(({ productPage }) => productPage);

  // VARIABLE/s
  const pos_message = settings?.pos_nonpos_switch_message || "";

  const { groupCode } = useParams();
  const { getSelectedFilter } = useFilters();
  const { multiYearValue } = useMultiYearFilter();

  //API Call's
  const [trigger, { isLoading, isFetching }] = useLazyGetQuoteQuery();

  // Trigger Quote Api
  const fetchIcQuote = async ({ sumInsured, deductible, whatChanged = "" }) => {
    const { data, error } = await trigger({
      insurer: quote?.product?.company?.alias,
      sum_insured_range: sumInsured,
      deductible: deductible,
      group: +groupCode || quote?.group?.id,
      base_plan_type: getSelectedFilter("baseplantype")?.code,
      tenure: multiYearValue?.code,
      plan_type: getSelectedFilter("plantype")?.code,
      journeyType,
    });
    if (error) {
      toast.error("Something Went Wrong", {
        position: "top-center",
        style: {
          background: colors.primary_color,
          color: "#fefefe",
        },
      });
      return;
    }
    if (data) {
      const fetchedQuote = data?.data?.find(quote => {
        if (journeyType === "health") {
          return (
            quote?.product?.id === initialQuote?.product?.id &&
            quote?.sum_insured === sumInsured
          );
        }
        if (journeyType === "top_up") {
          return (
            quote?.product?.id === initialQuote?.product?.id &&
            quote?.deductible === deductible
          );
        }
      });

      if (!fetchedQuote) {
        toast.error(`Plan is not available for selected ${whatChanged}`, {
          position: "top-center",
          style: {
            background: colors.primary_color,
            color: "#fefefe",
          },
        });
        return;
      }

      // update riders on the quote
      const updatedQuote = {
        ...fetchedQuote,
        [`${journeyType}_riders`]:
          initialQuote?.[`${journeyType}_riders`] || [],
      };
      setQuote(updatedQuote);
    }
  };
  // CUSTOM HOOK/s
  const {
    showLeftButton,
    scrollClick,
    setActiveKey,
    showRightButton,
    activeKey,
    setScrollableDivRef,
  } = useSeeDetailsTab(seeDetailSection);
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();
  const { handleB2BToB2C } = usePosSwitch();

  // HANDLER/s
  const handleClose = () => handleCloseSeeDetailsModal();
  const sumInsuredChangeHandler = option => {
    if (+option.value > 500000 && isPosJourney && brokerTenant("hero")) {
      handleB2BToB2C({
        onPopupClick: () => {
          fetchIcQuote({
            sumInsured: option?.value,
            deductible: quote?.deductible,
          });
        },
      });
      return;
    }
    if (
      +option?.value > 500000 &&
      !STORED_POS_COVER_MESSAGE() &&
      pos_message &&
      isPosJourney
    ) {
      handleOpenInfoPopup({
        title: "",
        description: removeHtmlTagsFromString(pos_message),
        buttonText: "OK",
        onClick: () => {
          sessionStorage.setItem("pos_cover_message", "true");
        },
      });
    }
    fetchIcQuote({
      sumInsured: option?.value,
      deductible: quote?.deductible,
      whatChanged: "Sum Insured",
    });
  };

  const deductibleChangeHandler = options => {
    fetchIcQuote({
      sumInsured: quote?.sum_insured,
      deductible: options?.value,
      whatChanged: "Deductible",
    });
  };

  // EFFECT/s
  useEffect(() => {
    setQuote(initialQuote);
  }, [initialQuote]);

  useEffect(() => {
    // Disable scrolling when modal is open
    if (seeDetailsModalOpen) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }

    return () => {
      unsetBodyOverflow();
    };
  }, [seeDetailsModalOpen]);

  return (
    <SeeDetailsPortal>
      <SeeDetailsModalContainerStyled>
        <SeeDetailsModalWrapperStyled>
          <SeeDetailsModalHeaderWrapperStyled>
            <DesktopSeeDetailsHeader
              quote={quote}
              sumInsuredChangeHandler={sumInsuredChangeHandler}
              deductibleChangeHandler={deductibleChangeHandler}
              onClose={handleClose}
              isLoading={
                isLoading || isFetching || isEnquiryLoading || ridersLoading
              }
            />

            <MobileSeeDetailsHeader
              quote={quote}
              sumInsuredChangeHandler={sumInsuredChangeHandler}
              deductibleChangeHandler={deductibleChangeHandler}
              isLoading={
                isLoading || isFetching || isEnquiryLoading || ridersLoading
              }
              handleClose={handleClose}
            />
          </SeeDetailsModalHeaderWrapperStyled>

          <SeeDetailsModalBodyWrapperStyled>
            <SeeDetailsTabsContainerStyled>
              {showLeftButton && (
                <LeftButton onClick={() => scrollClick("left")}>
                  <BiSolidLeftArrow />
                </LeftButton>
              )}
              <SeeDetailsTabsWrapperStyled
                colors={colors}
                id="tab-wrapper"
                ref={ref => setScrollableDivRef(ref)}
              >
                {PRODUCT_DETAILS_TABS.map((tab, index) => (
                  <TabStyled
                    key={index}
                    onClick={() => setActiveKey(tab.key)}
                    data-tab-active={tab.key === activeKey}
                    id={tab.key}
                    colors={colors}
                  >
                    <span className="left-corner" />
                    <FHTypography variant="h6" weight="base">
                      {tab.title}
                    </FHTypography>
                    <span className="right-corner" />
                  </TabStyled>
                ))}
              </SeeDetailsTabsWrapperStyled>
              {showRightButton && (
                <RightButton onClick={() => scrollClick("right")}>
                  <BiSolidRightArrow />
                </RightButton>
              )}
            </SeeDetailsTabsContainerStyled>
            <DetailsSectionWrap>
              {activeKey === "plan-details" && <PlanDetails quote={quote} />}
              {activeKey === "add-on-coverages" && (
                <AddOnCoverages quote={quote} />
              )}
              {activeKey === "cashless-hospitals" && (
                <CashlessHospitals quote={quote} />
              )}
              {activeKey === "claim-process" && <ClaimProcess quote={quote} />}
              {activeKey === "about-company" && <AboutCompany quote={quote} />}
            </DetailsSectionWrap>
          </SeeDetailsModalBodyWrapperStyled>

          <SeeDetailsModalFooterWrapperStyled>
            <MobileSeeDetailsFooter quote={quote} onClose={handleClose} />
          </SeeDetailsModalFooterWrapperStyled>
        </SeeDetailsModalWrapperStyled>
      </SeeDetailsModalContainerStyled>
    </SeeDetailsPortal>
  );
}

function SeeDetailsPortal({ children }) {
  return ReactDOM.createPortal(
    <>
      <BackdropStyled />
      {children}
    </>,
    document.getElementById("modal-root"),
  );
}
